import { EventDto } from "../../../../api/requests/event.dto";

export interface EventFormData {
  event: EventDto;
  channelSettings: ChannelSettings;
}

export interface ChannelSettings {
  sms: boolean;
  email: boolean;
  push: boolean;
  bot: boolean;
}

export default class EventFormConverter {
  public static convertFromDto(eventDto: EventDto): EventFormData {
    const channelSettings: ChannelSettings = {
      sms: eventDto.smsTemplate !== undefined,
      email: eventDto.emailTemplate !== undefined,
      push: eventDto.pushTemplate !== undefined,
      bot: eventDto.botTemplate !== undefined
    };
    const eventFormData: EventFormData = {
      event: eventDto,
      channelSettings
    };
    return eventFormData;
  }
  public static convertToDto(formData: EventFormData): EventDto {
    const eventData = formData.event;
    if (!formData.channelSettings.email) {
      eventData.emailTemplate = undefined;
    }

    if (!formData.channelSettings.sms) {
      eventData.smsTemplate = undefined;
    }

    if (!formData.channelSettings.push) {
      eventData.pushTemplate = undefined;
    }

    if (!formData.channelSettings.bot) {
      eventData.botTemplate = undefined;
    }

    return eventData;
  }
}
