import React, { useEffect } from "react";
import IBundleDto from "../../../api/responses/IBundleDto";
import CrudTableContainer from "../components/crudTable";
import IProjectDto from "../../../api/responses/IProjectDto";
import { Platform } from "../../../models/platform";

interface IProps {
  projects: IProjectDto[];
}

const BundleTab = (props: IProps) => {
  const BundleTable = CrudTableContainer<IBundleDto>();
  return (
    <BundleTable
      entityName="bundle"
      title="Бандлы"
      columns={[
        { title: "Id", field: "id", editable: "onAdd", hidden: false },
        {
          title: "Проект",
          field: "project.id",
          lookup: props.projects.reduce(
            (acc, project) => ({ ...acc, [project.id]: project.title }),
            {}
          )
        },
        { title: "Описание", field: "description" },
        {
          title: "Платформа",
          field: "platform",
          lookup: { [Platform.ANDROID]: "Андроид", [Platform.IOS]: "Иос" }
        }
      ]}
    />
  );
};

export default BundleTab;
