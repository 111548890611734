import React, { useEffect } from "react";
import { Link, Route, Switch } from "react-router-dom";
import Tab from "@material-ui/core/Tab";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
export enum RouteAdminTab {
  users = "/admin/users",
  projects = "/admin/projects",
  bundles = "/admin/bundles",
  assemblies = "/admin/assemblies",
  templates = "/admin/templates",
  integration = "/admin/integration",
  settings = "/admin/settings"
}

interface IRouteTabItemProps {
  label: string;
  value: RouteAdminTab;
}
export function RouteTabItem(props: IRouteTabItemProps) {
  return (
    <Tab
      label={props.label}
      component={Link}
      to={props.value}
      value={props.value}
    ></Tab>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabpanel: {
      width: "100%",
      height: "100%"
    },
    tabPanelTitle: {
      marginLeft: 30,
      marginTop: 10,
      marginBottom: 20
    },
    tabContent: {
      marginLeft: 30,
      marginRight: 30,
      height: "100%"
    }
  })
);

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, ...other } = props;

  return (
    <div role="tabpanel" className={classes.tabpanel} {...other}>
      <div className={classes.tabContent}>{children}</div>
    </div>
  );
}

interface IRouteTabProps {
  children: React.ReactNode;
  label: string;
  path: RouteAdminTab;
}

export function RouteForTab(props: IRouteTabProps) {
  return (
    <Route
      path={props.path}
      render={pr => <TabPanel>{props.children}</TabPanel>}
    ></Route>
  );
}
