import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import {
  Button,
  createStyles,
  Paper,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../assets/images/background.jpg";
import { ILoginPayload, login } from "../../ducks/auth";
import ProgressIndicator from "../../components/progressIndicator";
import ILoginPayloadResponseDto from "../../api/responses/ILoginPayloadResponseDto";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      backgroundSize: "100% 100%",
      background: ` linear-gradient(
          rgba(0, 0, 0, 0.6),
          rgba(0, 0, 0, 0.6)
        ), url(${BackgroundImage})`,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    content: {
      width: "300px",
      height: "300px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      background: "rgba(255,255,255,0.9)",
      borderRadius: "20px",
      padding: "20px"
    },
    textField: {
      margin: 20
    },
    header: {
      fontSize: 50,
      color: "white",
      marginBottom: 20
    },
    topDescriptionLink: {
      color: "white",
      marginTop: 5
    }
  })
);

interface RouteParams {
  id: string;
}

interface IStatedProps {
  isFetching: boolean;
  loginResponse: ILoginPayloadResponseDto;
}

interface IDispatchedProps {
  doLogin: (request: ILoginPayload) => void;
}

interface IProps
  extends IStatedProps,
    IDispatchedProps,
    RouteComponentProps<RouteParams> {}

const LoginPage = (props: IProps) => {
  const classes = useStyles();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();

  const onTextFieldChange = (event: any, id: string) => {
    switch (id) {
      case "login":
        setLogin(event.target.value);
        break;

      case "password":
        setPassword(event.target.value);
        break;
    }
  };

  const handleClick = (event: any) => {
    props.doLogin({
      username: login,
      password
    });
  };

  useEffect(() => {
    if (props.loginResponse.jwtToken) {
      props.history.push("/");
    }
  });

  return (
    <div className={classes.main}>
      <span className={classes.header}>Assembly Storage</span>
      <Paper className={classes.content}>
        <TextField
          className={classes.textField}
          id="login"
          fullWidth={true}
          label="Логин"
          margin="normal"
          helperText="Логин АД (почты)"
          variant="outlined"
          onChange={e => onTextFieldChange(e, "login")}
        />

        <TextField
          className={classes.textField}
          fullWidth={true}
          id="password-input"
          type="password"
          label="Пароль"
          helperText="Пароль от АД (почты)"
          variant="outlined"
          onChange={e => onTextFieldChange(e, "password")}
        />

        {props.isFetching && <ProgressIndicator />}
        <Button
          style={{ marginTop: 20 }}
          disabled={!login || !password}
          variant="contained"
          color="primary"
          onClick={event => handleClick(event)}
        >
          Войти
        </Button>
      </Paper>

      <a
        className={classes.topDescriptionLink}
        href="http://gitlab.bmnet.ru/GU/internal-projects/assembly-storage"
      >
        Source code
      </a>
    </div>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: any): IStatedProps => ({
  isFetching: state.authBranch.loginData.isFetching,
  loginResponse: state.authBranch.loginData.data
});

export default withRouter(
  connect<IStatedProps, IDispatchedProps, {}, IReduxState>(mapStateToProps, {
    doLogin: login.started
  })(LoginPage)
);
