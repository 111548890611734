import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken } from "../../utils/authUtils";
import { IBaseApiResponse } from "./IBaseApiResponse";
import { ApiError } from "../../models/apiError";

export interface IRequestOptions {
  token?: string;
}

export default abstract class BaseApiService {
  protected async getRequest<R>(
    url: string,
    options?: IRequestOptions
  ): Promise<R> {
    const get = axios.get(url, this.getConfig(options));
    return await this.executeRequest(get);
  }

  protected async putRequest<R>(
    url: string,
    data: any,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.put(url, data, this.getConfig(options))
    );
  }

  protected async patchRequest<R>(
    url: string,
    data: any,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.patch(url, data, this.getConfig(options))
    );
  }

  protected async postRequest<R>(
    url: string,
    data: any,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.post(url, data, this.getConfig(options))
    );
  }

  protected async deleteRequest<R>(
    url: string,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.delete(url, this.getConfig(options))
    );
  }

  protected getConfig(options?: IRequestOptions): AxiosRequestConfig {
    let config = {};
    let token;
    const tokenFromStorage = getToken();
    if (tokenFromStorage) {
      token = tokenFromStorage;
    }
    if (options && options.token) {
      token = options.token;
    }

    if (token) {
      config = {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 15000
      };
    }
    return config;
  }

  protected async executeRequest<R>(
    request: Promise<AxiosResponse<IBaseApiResponse<R>>>
  ): Promise<R> {
    try {
      const response = await request;
      const serverData = response.data as IBaseApiResponse<R>;
      if (serverData.errorCode !== 0) {
        throw new ApiError(serverData.errorCode, serverData.errorMessage);
      } else {
        return serverData.result;
      }
    } catch (e) {
      if (e instanceof ApiError) {
        throw e;
      } else {
        throw new ApiError(500, e.message);
      }
    }
  }
}
