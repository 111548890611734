import { EventDto } from "../../../api/requests/event.dto";
import { RouteComponentProps } from "react-router";
import * as React from "react";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { connect } from "react-redux";
import {
  createOrUpdateEvent,
  CreateOrUpdateEventPayload,
  getEventById
} from "../../../ducks/event";
import { TextField } from "final-form-material-ui";
import { ExpandMore, ImageSearch } from "@material-ui/icons";
import TitlePageWrapper from "../../titlePageWrapper";
import EventFormConverter, { EventFormData } from "./model/eventFormModel";
import ToggleChannelButtonsFormAdapter from "./components/toggleChannelButtonsFormAdapter";
import { ToggleChannelType } from "./components/toggleChannelButtons";
import HtmlPreviewAdapter from "./components/htmlPreviewAdapter";
import PopupDialog from "../../dialog";
import ImageManager from "./components/imagecomponents/imageManager";
import { IReduxState } from "../../../../reduxx/reducer";

export interface State {
  newOrEditEvent?: EventFormData;
  eventId?: number;
  title: string;
  isImagePickerOpen: boolean;
}

interface DispatchedProps {
  getEventById: (eventId: number) => void;
  createOrUpdateEvent: (payload: CreateOrUpdateEventPayload) => void;
}

interface StatedProps {
  event: EventDto;
  getEventFetching: boolean;
  isError: boolean;
}

interface Props extends DispatchedProps, StatedProps, RouteComponentProps {}

class AddEventPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const params = props.match.params;
    const eventId = (params as any).eventId;
    const streamId = (params as any).streamId;
    if (eventId) {
      // редактирование
      this.state = {
        eventId: parseInt(eventId),
        title: "Редактирование",
        isImagePickerOpen: false
      };
    } else {
      this.state = {
        newOrEditEvent: EventFormConverter.convertFromDto({
          title: "",
          description: "",
          eventId: 0,
          streamId: parseInt(streamId)
        }),
        title: "Новое событие",
        isImagePickerOpen: false
      };
    }
  }

  public componentDidMount(): void {
    if (!this.state.newOrEditEvent && this.state.eventId) {
      this.props.getEventById(this.state.eventId);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!this.state.newOrEditEvent && this.state.eventId) {
      if (prevProps.event !== this.props.event) {
        this.setState({
          newOrEditEvent: EventFormConverter.convertFromDto(this.props.event)
        });
      }
    }
  }

  public render() {
    return (
      <TitlePageWrapper
        isBlockingLoader={this.props.getEventFetching}
        isLinearLoader={this.props.getEventFetching}
        isError={this.props.isError}
        title={this.state.title}
        additionalButtons={
          <IconButton onClick={this.openPickerImagePopup} color="inherit">
            <ImageSearch />
          </IconButton>
        }
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={this.state.newOrEditEvent}
          mutators={{
            setErrorTemplate: (arg: any, state: any, utils: any) => {
              utils.changeValue(state, "", () => 1);
            }
          }}
          render={({ handleSubmit }) => (
            <div>
              <form
                style={{ width: "100%" }}
                onSubmit={handleSubmit}
                id="addOrEdit"
              >
                <>
                  <Field
                    required={true}
                    name="event.eventId"
                    fullWidth={true}
                    component={TextField}
                    disabled={this.state.eventId !== undefined}
                    type="text"
                    label="Идентификатор события"
                  />
                  <Field
                    required={true}
                    name="event.title"
                    fullWidth={true}
                    component={TextField}
                    type="text"
                    label="Название"
                  />
                  <Field
                    required={true}
                    name="event.description"
                    multiline={true}
                    fullWidth={true}
                    component={TextField}
                    type="text"
                    label="Описание"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 220,
                      marginTop: 10,
                      marginBottom: 10
                    }}
                  >
                    <Field
                      required={true}
                      name="channelSettings.push"
                      fullWidth={true}
                      channelType={ToggleChannelType.push}
                      component={ToggleChannelButtonsFormAdapter}
                      label="Название"
                    />
                    <Field
                      required={true}
                      name="channelSettings.email"
                      fullWidth={true}
                      channelType={ToggleChannelType.email}
                      component={ToggleChannelButtonsFormAdapter}
                      label="Название"
                    />
                    <Field
                      required={true}
                      name="channelSettings.sms"
                      fullWidth={true}
                      channelType={ToggleChannelType.sms}
                      component={ToggleChannelButtonsFormAdapter}
                      label="Название"
                    />

                    <Field
                      required={true}
                      name="channelSettings.bot"
                      fullWidth={true}
                      channelType={ToggleChannelType.bot}
                      component={ToggleChannelButtonsFormAdapter}
                      label="Название"
                    />
                  </div>

                  <Condition when={`channelSettings.push`} is={true}>
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography>PUSH</Typography>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <Field
                            required={true}
                            name="event.pushTemplate.title"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Название"
                          />
                          <Field
                            required={true}
                            name="event.pushTemplate.routingUri"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Роутинг перехода МП"
                          />
                          <Field
                            required={true}
                            name="event.pushTemplate.siteUrl"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Роутинг перехода сайта"
                          />
                          <Field
                            required={true}
                            name="event.pushTemplate.icon"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Иконка"
                          />

                          <Field
                            required={true}
                            name="event.pushTemplate.body"
                            multiline={true}
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Описание"
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Condition>
                  <Condition when={`channelSettings.email`} is={true}>
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography>EMAIL</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <Field
                            required={true}
                            name="event.emailTemplate.subject"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Тема письма"
                          />
                          <Grid style={{ marginTop: 10 }} container={true}>
                            <Grid item={true} xs={6}>
                              <Field
                                required={true}
                                name="event.emailTemplate.body"
                                multiline={true}
                                fullWidth={true}
                                component={TextField}
                                type="text"
                                label="Тело письма"
                              />
                            </Grid>
                            <Grid item={true} xs={6}>
                              <Paper style={{ padding: 10, margin: 5 }}>
                                <Field
                                  required={true}
                                  name="event.emailTemplate.body"
                                  multiline={true}
                                  fullWidth={true}
                                  component={HtmlPreviewAdapter}
                                  type="text"
                                  label="Тело письма"
                                />
                              </Paper>
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Condition>
                  <Condition when={`channelSettings.sms`} is={true}>
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography>SMS</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <Field
                            required={true}
                            name="event.smsTemplate.body"
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Тело смс"
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Condition>
                  <Condition when={`channelSettings.bot`} is={true}>
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Typography>BOT</Typography>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails>
                        <div style={{ width: "100%" }}>
                          <Field
                            required={true}
                            name="event.botTemplate.body"
                            multiline={true}
                            fullWidth={true}
                            component={TextField}
                            type="text"
                            label="Тело сообщения (plain-text или маркдаун)"
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Condition>
                </>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}
                >
                  <Button
                    onClick={e => this.goBack()}
                    style={{ margin: "10px" }}
                    variant="outlined"
                    type="button"
                    color="primary"
                  >
                    Отменить
                  </Button>
                  <Button
                    style={{ margin: "10px" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {this.state.eventId ? "Обновить" : "Создать"}
                  </Button>
                </div>
              </form>
            </div>
          )}
        />
        <PopupDialog
          title="Загрузка картинок"
          onClose={this.closePickerImagePopup}
          saveButtonDisabled={false}
          isOpen={this.state.isImagePickerOpen}
        >
          <ImageManager />
        </PopupDialog>
      </TitlePageWrapper>
    );
  }

  private validate = (values: EventFormData) => {
    const errors: any = {};
    if (!values.event.eventId) {
      errors.event.eventId = "Обязательно";
    }
    if (!values.event.title) {
      errors.event.eventId = "Обязательно";
    }

    return errors;
  };

  private onSubmit = (values: EventFormData) => {
    const resultDto = EventFormConverter.convertToDto(values);
    const isUpdate = this.state.eventId !== undefined;
    this.props.createOrUpdateEvent({ event: resultDto, isUpdate });
  };

  private goBack = () => {
    this.props.history.goBack();
  };

  private openPickerImagePopup = () => {
    this.setState({ isImagePickerOpen: true });
  };
  private closePickerImagePopup = () => {
    this.setState({ isImagePickerOpen: false });
  };
}

const Condition = (input: { when: any; is: any; children: any }) => (
  <Field name={input.when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === input.is ? input.children : null)}
  </Field>
);

const mapStateToProps = (state: IReduxState, ownProps: any): StatedProps => ({
  event: state.eventTemplatesBranch.event.getIn([
    "editedEvent",
    "data"
  ]) as EventDto,
  getEventFetching: state.eventTemplatesBranch.event.getIn([
    "editedEvent",
    "isFetching"
  ]) as boolean,
  isError: state.eventTemplatesBranch.event.getIn([
    "editedEvent",
    "isError"
  ]) as boolean
});

export default connect(mapStateToProps, {
  getEventById: getEventById.started,
  createOrUpdateEvent: createOrUpdateEvent.started
})(AddEventPage);
