import React from "react";
import { Route } from "react-router-dom";
import "./App.css";
import StreamPage from "./components/pages/stream/streamPage";
import EventListPage from "./components/pages/event/eventListPage";
import AddEventPage from "./components/pages/event/addEventPage";
import { Switch } from "react-router-dom";
require("dotenv").config();

const EventServiceApp: React.FC = () => {
  return (
    <Switch>
      <Route path="/admin/templates" exact component={StreamPage} />
      <Route
        exact={true}
        path="/admin/templates/:streamId/event"
        component={EventListPage}
      />
      <Route
        exact={true}
        path="/admin/templates/:streamId/event/add"
        component={AddEventPage}
      />
      <Route
        exact={true}
        path="/admin/templates/:streamId/event/edit/:eventId"
        component={AddEventPage}
      />
    </Switch>
  );
};

export default EventServiceApp;
