import * as React from "react"; // importing FunctionComponent
import {
  createStyles,
  IconButton,
  List,
  ListItemSecondaryAction,
  makeStyles,
  Theme
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Skeleton from "@material-ui/lab/Skeleton";
import StreamDto from "../../../../api/requests/stream.dto";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      width: "100%",
      padding: 5,
      marginBottom: 10
    },
    orangeAvatar: {
      margin: 10,
      color: "#fff",
      backgroundColor: theme.palette.secondary.main
    }
  })
);

type StreamItemProps = {
  stream: StreamDto;
  onSelect: (streamId?: number) => void;
  onDelete: (stream: StreamDto) => void;
  onEdit: (stream: StreamDto) => void;
};

type StreamListProps = {
  streams: StreamDto[];
  isFetching: boolean;
  onSelect: (streamId?: number) => void;
  onEdit: (stream: StreamDto) => void;
  onDelete: (stream: StreamDto) => void;
};

const StreamItem: React.FunctionComponent<StreamItemProps> = props => {
  const styles = useStyles();
  const { stream, onSelect, onDelete, onEdit } = props;
  return (
    <Paper className={styles.paper}>
      <ListItem onClick={e => onSelect(stream.id)}>
        <ListItemAvatar>
          {stream ? (
            <Avatar className={styles.orangeAvatar}>{stream.id}</Avatar>
          ) : (
            <Skeleton variant="circle" width={40} height={40} />
          )}
        </ListItemAvatar>
        {stream ? (
          <ListItemText primary={stream.title} secondary={stream.description} />
        ) : (
          <ListItemText
            primary={<Skeleton height={6} width="30%" />}
            secondary={
              <>
                <Skeleton height={6} width="80%" />
                <Skeleton height={6} width="40%" />
              </>
            }
          />
        )}

        <ListItemSecondaryAction>
          <IconButton
            onClick={e => onEdit(stream)}
            edge="end"
            aria-label="delete"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={e => onDelete(stream)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  );
};

const StreamList: React.FunctionComponent<StreamListProps> = streamsListProps => {
  const styles = useStyles();
  const { streams, onSelect, isFetching, onDelete, onEdit } = streamsListProps;
  const items = isFetching ? Array.from(new Array(5)) : streams;
  return (
    <List className={styles.root}>
      {items.map((stream, index) => (
        <StreamItem
          key={stream ? stream.id : index}
          stream={stream}
          onSelect={onSelect}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </List>
  );
};
export default StreamList;
