import RecursiveComponent from "react-json-component";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Theme,
  Typography
} from "@material-ui/core";

export interface IProps {
  changes?: string;
  attributes: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%"
    }
  })
);

const AssemblyAttributes = (props: IProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | boolean>("diff");
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };
  let changesLines;
  if (props.changes) {
    changesLines = props.changes.split("\n").map((item, i) => {
      return (
        <b>
          <p key={i}>{item}</p>
        </b>
      );
    });
  }
  return (
    <>
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === "diff"}
          onChange={handleChange("diff")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>Что нового</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <>{changesLines}</>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "analyzeInfo"}
          onChange={handleChange("analyzeInfo")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Результаты анализа сборки</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <RecursiveComponent
              property={props.attributes}
              propertyName="Анализ сборки"
              excludeBottomBorder={false}
              rootProperty={true}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </>
  );
};

export default AssemblyAttributes;
