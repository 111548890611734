import appConfig from "../appConfig";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import actionCreatorFactory from "typescript-fsa";

export interface PushData {
  title: string;
  body: string;
  clickAction: string;
  icon: string;
}
export interface IPushBranch {
  push: PushData;
}

export const moduleName = "push";
const prefix = `${appConfig.appName}/${moduleName}`;

export const createEmptySettings = (): IPushBranch => {
  return { push: { body: "", title: "", clickAction: "", icon: "" } };
};

const factory = actionCreatorFactory(prefix);
export const setPushData = factory<PushData>("SET_PUSH_DATA");

export const pushReducer = reducerWithInitialState(createEmptySettings()).case(
  setPushData,
  (state, pushData) => ({
    ...state,
    push: pushData
  })
);
