import StreamDto from "./requests/stream.dto";
import axios, { AxiosResponse } from "axios";
import { EventDto } from "./requests/event.dto";
import { ApiError } from "../models/apiError";
import ImageDto from "./requests/image.dto";
import DeviceDto from "./requests/device.dto";

export interface BaseApiResponse<T> {
  errorCode: number;
  errorMessage: string;
  validation?: any;
  result: T;
}

export default class EventApiService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = `/notifications`;
  }

  public async registerDevice(device: DeviceDto): Promise<DeviceDto> {
    const url = `${apiUrls.device}/register`;
    const result = await this.postRequest<DeviceDto>(url, device);
    return result;
  }

  public async createStream(stream: StreamDto): Promise<StreamDto> {
    const url = `${apiUrls.stream}`;
    const result = await this.postRequest<StreamDto>(url, stream);
    return result;
  }

  public async updateStream(stream: StreamDto): Promise<StreamDto> {
    const url = `${apiUrls.stream}`;
    const result = await this.putRequest<StreamDto>(url, stream);
    return result;
  }

  public async getStreams(): Promise<StreamDto[]> {
    const url = `${apiUrls.stream}`;
    const result = await this.getRequest<StreamDto[]>(url);
    return result;
  }

  public async getImages(): Promise<ImageDto[]> {
    const url = `${apiUrls.image}`;
    const result = await this.getRequest<ImageDto[]>(url);
    return result;
  }

  public async deleteImage(image: ImageDto): Promise<boolean> {
    const url = `${apiUrls.image}/${image.id}`;
    const result = await this.deleteRequest<boolean>(url);
    return result;
  }

  public async deleteStream(stream: StreamDto): Promise<boolean> {
    const url = `${apiUrls.stream}/${stream.id}`;
    const result = await this.deleteRequest<boolean>(url);
    return result;
  }

  public async getAllEvents(): Promise<EventDto[]> {
    const url = `${apiUrls.event}`;
    const result = await this.getRequest<EventDto[]>(url);
    return result;
  }

  public async getEventsByStreamId(streamId: number): Promise<EventDto[]> {
    const url = `${apiUrls.event}/${streamId}`;
    const result = await this.getRequest<EventDto[]>(url);
    return result;
  }

  public async getEventById(eventId: number): Promise<EventDto> {
    const url = `${apiUrls.event}/getById/${eventId}`;
    const result = await this.getRequest<EventDto>(url);

    return result;
  }

  public async deleteEvent(event: EventDto): Promise<boolean> {
    const url = `${apiUrls.event}/${event.eventId}`;
    const result = await this.deleteRequest<boolean>(url);
    return result;
  }

  public async createEvent(event: EventDto): Promise<EventDto> {
    const response = await this.putRequest<EventDto>(`${apiUrls.event}`, event);
    return response;
  }

  public async uploadFile(file: File) {
    const url = `${this.baseUrl}${apiUrls.image}`;
    const formData = new FormData();
    formData.append("file", new Blob([file]), file.name);
    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };
    return await this.executeRequest(axios.post(url, formData, config));
  }

  public async updateEvent(event: EventDto): Promise<EventDto> {
    const response = await this.postRequest<EventDto>(
      `${apiUrls.event}`,
      event
    );
    return response;
  }

  private async getRequest<R>(url: string): Promise<R> {
    return await this.executeRequest(axios.get(`${this.baseUrl}${url}`));
  }

  private async putRequest<R>(url: string, data: any): Promise<R> {
    return await this.executeRequest(axios.put(`${this.baseUrl}${url}`, data));
  }

  private async postRequest<R>(url: string, data: any): Promise<R> {
    return await this.executeRequest(axios.post(`${this.baseUrl}${url}`, data));
  }

  private async deleteRequest<R>(url: string): Promise<R> {
    return await this.executeRequest(axios.delete(`${this.baseUrl}${url}`));
  }

  private async executeRequest<R>(
    request: Promise<AxiosResponse<BaseApiResponse<R>>>
  ) {
    try {
      const response = await request;
      const serverData = response.data as BaseApiResponse<R>;
      if (serverData.errorCode !== 0) {
        const error = new ApiError(
          serverData.errorCode,
          serverData.errorMessage
        );
        throw error;
      } else {
        return serverData.result;
      }
    } catch (e) {
      if (e instanceof ApiError) {
        throw e;
      } else {
        throw new ApiError(500, e.message);
      }
    }
  }
}
const apiUrls = {
  stream: "/stream",
  event: "/eventTemplate",
  image: "/image",
  device: "/device"
};
