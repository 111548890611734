import React, { CSSProperties } from "react";
import {
  StyleRulesCallback,
  Theme,
  useTheme,
  withStyles,
  WithStyles,
  withTheme
} from "@material-ui/core";
import MaterialTable, { Column, DetailPanel } from "material-table";
import CrudService from "../../../api/crud/crudService";
import IBaseIdResponse from "../../../api/base/IBaseIdResponse";
import { connect } from "react-redux";
import { crudRequestError } from "../../../ducks/auth";
import { ApiError } from "../../../models/apiError";
import Typography from "@material-ui/core/Typography";

type CrudTableClassKey =
  | "leftPanel"
  | "tableContainer"
  | "table"
  | "tabPanelTitle";

const crudTableStyles: StyleRulesCallback<Theme, {}, CrudTableClassKey> = (
  theme: Theme
) => ({
  leftPanel: {
    paddingRight: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "right"
  },
  tabPanelTitle: {
    marginTop: 10,
    marginBottom: 20
  },
  tableContainer: {},
  table: {
    width: "100%"
  }
});

interface IDispatchedProps {
  error: (error: ApiError) => void;
}

interface IProps<T extends IBaseIdResponse> extends IDispatchedProps {
  entityName: string;
  title: string;
  columns: Column<T>[];
  detailPanel?:
    | ((rowData: T) => React.ReactNode)
    | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[];
  dense?: boolean;
  theme: Theme;
}

class CrudTable<T extends IBaseIdResponse> extends React.Component<
  IProps<T> & WithStyles<CrudTableClassKey>
> {
  constructor(props: IProps<T> & WithStyles<CrudTableClassKey>) {
    super(props);
  }

  public render() {
    const { classes, dense, theme } = this.props;
    const crudService = new CrudService<T>(this.props.entityName);
    const props = this.props;
    return (
      <div>
        <MaterialTable<T>
          title={
            <Typography className={classes.tabPanelTitle} variant="h2">
              {props.title}
            </Typography>
          }
          columns={props.columns}
          localization={{
            grouping: {
              placeholder:
                "Для группировки - перетащите названия колонки в эту область"
            },
            header: {
              actions: "Действия"
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} из {count}",
              labelRowsSelect: "строк"
            },
            toolbar: { searchTooltip: "Поиск", searchPlaceholder: "Поиск" },
            body: {
              emptyDataSourceMessage: "Нет подходящих для условий сборок",
              filterRow: {
                filterTooltip: "Фильтр"
              }
            }
          }}
          detailPanel={props.detailPanel}
          data={query =>
            new Promise((resolve, reject) => {
              crudService
                .getMany<T[]>(query.pageSize, query.page + 1)
                .then(result => {
                  resolve({
                    data: result.data,
                    page: result.page - 1,
                    totalCount: result.total
                  });
                })
                .catch(exception => props.error(exception));
            })
          }
          options={{
            grouping: false,
            sorting: true,
            toolbar: true,
            padding: dense ? "dense" : "default",
            actionsColumnIndex: -1,
            emptyRowsWhenPaging: true,
            tableLayout: "fixed",
            pageSize: 10,
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: "#FFF"
            }
          }}
          editable={{
            isEditable: rowData => true, // only name(a) rows would be editable
            isDeletable: rowData => true, // only name(a) rows would be deletable
            onRowAdd: newData => crudService.createOne(newData),
            onRowUpdate: (newData, oldData) =>
              crudService.updateOne(newData.id, newData),
            onRowDelete: oldData => crudService.deleteOne(oldData.id)
          }}
        />
      </div>
    );
  }
}

export default function CrudTableContainer<T extends IBaseIdResponse>() {
  return connect<void, IDispatchedProps>(null, {
    error: (error: ApiError) => crudRequestError(error)
  })(
    withTheme(
      withStyles(crudTableStyles)(
        CrudTable as new (props: IProps<T>) => CrudTable<T>
      )
    )
  );
}
