import { ILoadingData } from "../models/loadingData";
import IUserRequestDto from "../api/requests/user.dto";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { deleteToken, getToken } from "../utils/authUtils";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiError } from "../models/apiError";
import { SagaIterator } from "redux-saga";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

import { bindAsyncAction } from "../utils/bindAsyncAction";
import ApiService from "../api/apiService";
import { push } from "connected-react-router";
import IUserSubscriptionDto from "../api/responses/IUserSubscriptionDto";
import { IReduxState } from "../reduxx/reducer";
import NotificationService from "../api/notificationService";
import ISubscriptionDto from "../api/responses/ISubscriptionDto";
import appConfig from "../appConfig";
import { ISettingsBranch } from "./settings";
import ILoginPayloadResponseDto from "../api/responses/ILoginPayloadResponseDto";
import EventApiService from "../eventTemplateApp/api/eventApiService";
import DeviceDto from "../eventTemplateApp/api/requests/device.dto";
import { Platform } from "../models/platform";

export const moduleName = "auth";
const prefix = `${appConfig.appName}/${moduleName}`;

export interface ILoginPayload {
  username: string;
  password: string;
}

export interface IGetSubscriptions {
  userId?: string;
}

export interface IChangeSubscriptionPayload {
  streamId: number;
  subscriptionId?: number;
  isSms: boolean;
  isEmail: boolean;
  isPush: boolean;
}

export interface IAuthStateBranch {
  token: string | null;
  loginData: ILoadingData<ILoginPayloadResponseDto>;
  userProfile: ILoadingData<IUserRequestDto>;
  subscriptions: ILoadingData<IUserSubscriptionDto[]>;
  changeSubscriptionFetching: boolean;
}

const factory = actionCreatorFactory(prefix);

export const createEmpty = (): IAuthStateBranch => {
  return {
    userProfile: {
      isFetching: false,
      isError: false,
      data: {
        displayName: "",
        email: "",
        id: "",
        username: "",
        initials: "",
        isAdmin: false,
        position: ""
      }
    },
    loginData: {
      isFetching: false,
      isError: false,
      data: { jwtToken: "", userId: "" }
    },
    subscriptions: { isFetching: false, isError: false, data: [] },
    token: getToken(),
    changeSubscriptionFetching: false
  };
};

export const logout = factory("LOGOUT");
export const crudRequestError = factory<ApiError>("CRUD_REQUEST_FAIL");
export const login = factory.async<
  ILoginPayload,
  ILoginPayloadResponseDto,
  ApiError
>("LOGIN");
export const getProfile = factory.async<void, IUserRequestDto, ApiError>(
  "GET_PROFILE"
);
export const getSubscriptions = factory.async<
  IGetSubscriptions,
  IUserSubscriptionDto[],
  ApiError
>("GET_SUBSCRIPTIONS");

export const changeSubscription = factory.async<
  IChangeSubscriptionPayload,
  ISubscriptionDto,
  ApiError
>("CHANGE_SUBSCRIPTION");

export const authReducer = reducerWithInitialState(createEmpty())
  .case(login.started, state => ({
    ...state,
    loginData: { ...state.loginData, isFetching: true }
  }))
  .case(login.failed, state => ({
    ...state,
    loginData: { ...state.loginData, isFetching: false }
  }))
  .case(login.done, (state, payload) => ({
    ...state,
    loginData: {
      isFetching: false,
      data: payload.result,
      isError: false
    },
    token: payload.result.userId
  }))
  .case(getProfile.started, state => ({
    ...state,
    userProfile: { ...state.userProfile, isFetching: true }
  }))
  .case(getProfile.failed, state => ({
    ...state,
    userProfile: { ...state.userProfile, isFetching: false }
  }))
  .case(getProfile.done, (state, payload) => ({
    ...state,
    userProfile: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(getSubscriptions.started, state => ({
    ...state,
    subscriptions: { ...state.subscriptions, isFetching: true }
  }))
  .case(getSubscriptions.failed, state => ({
    ...state,
    subscriptions: { ...state.subscriptions, isFetching: false }
  }))
  .case(getSubscriptions.done, (state, payload) => ({
    ...state,
    subscriptions: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(changeSubscription.started, state => ({
    ...state,
    changeSubscriptionFetching: true
  }))
  .case(changeSubscription.failed, state => ({
    ...state,
    changeSubscriptionFetching: false
  }))
  .case(changeSubscription.done, (state, payload) => ({
    ...state,
    changeSubscriptionFetching: false
  }))
  .case(logout, (state: any) => ({
    ...state,
    loginData: { ...state.loginData, isFetching: false, data: "" },
    token: null
  }));

const getUserProfileSelector = (state: IReduxState): IUserRequestDto =>
  state.authBranch.userProfile.data;

const getGuidAndTokenSelector = (state: IReduxState): ISettingsBranch =>
  state.settings;

const loginWorker = bindAsyncAction(login)(function*(
  param: Action<ILoginPayload>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call([apiService, apiService.login], param.payload);
  const settings: ISettingsBranch = yield select(getGuidAndTokenSelector);
  if (settings.cloud_token && settings.guid) {
    const eventService = new EventApiService();
    const payload: DeviceDto = {
      guid: settings.guid,
      userId: response.userId,
      platform: Platform.SITE,
      cloudToken: settings.cloud_token
    };
    const registerResponse = yield call(
      [eventService, eventService.registerDevice],
      payload
    );
  }
  return response;
});

const getProfileWorker = bindAsyncAction(getProfile)(function*(): SagaIterator {
  const apiService = new ApiService();
  const response = yield call([apiService, apiService.getProfile]);
  return response;
});

const getSubscriptionsWorker = bindAsyncAction(getSubscriptions)(function*(
  payload: Action<IGetSubscriptions>
): SagaIterator {
  const notificationService = new NotificationService();
  let id;
  if (payload.payload.userId) {
    id = payload.payload.userId;
  } else {
    const profile: IUserRequestDto = yield select(getUserProfileSelector);
    id = profile.id;
  }

  const response = yield call(
    [notificationService, notificationService.getSubscriptions],
    id
  );
  return response;
});

const changeSubscriptionWorker = bindAsyncAction(changeSubscription)(function*(
  params: Action<IChangeSubscriptionPayload>
): SagaIterator {
  const notificationService = new NotificationService();
  const payload = params.payload;
  const profile: IUserRequestDto = yield select(getUserProfileSelector);
  //новая подписка
  const newOrUpdatedSubscription: ISubscriptionDto = {
    isEmail: payload.isEmail,
    isPush: payload.isPush,
    isSms: payload.isSms,
    streamId: payload.streamId,
    id: payload.subscriptionId
  };

  let response;
  if (payload.subscriptionId) {
    //существующая подписка
    response = yield call(
      [notificationService, notificationService.updateSubscription],
      profile.id,
      newOrUpdatedSubscription
    );
  } else {
    response = yield call(
      [notificationService, notificationService.createSubscription],
      profile.id,
      newOrUpdatedSubscription
    );
  }
  yield delay(500);
  yield put(getSubscriptions.started({}));
  return response;
});

function* clearToken() {
  deleteToken();
  yield put(push("/login"));
}

export function* saga(): SagaIterator {
  yield takeEvery(login.started, loginWorker);
  yield takeEvery(logout, clearToken);
  yield takeEvery(getProfile.started, getProfileWorker);
  yield takeEvery(getSubscriptions.started, getSubscriptionsWorker);
  yield takeEvery(changeSubscription.started, changeSubscriptionWorker);
}
