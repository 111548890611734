import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  createStyles,
  IconButton,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import {
  ExitToApp,
  Settings,
  Person,
  ArrowBack,
  Help
} from "@material-ui/icons";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import { logout } from "../../ducks/auth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      height: "100%",
      margin: "10px",
      padding: "10px"
    },
    mainheader: {
      textDecoration: "none",
      color: "inherit"
    },
    content: {
      padding: 10,
      height: "100%"
    },
    root: {
      flexGrow: 1,
      height: "100%"
    },
    title_block: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 5
    },
    backIcon: {
      padding: 0,
      marginRight: 10
    },
    menuButton: {
      marginRight: theme.spacing(2)
    }
  })
);
interface IStatedProps {}
interface IDispatchedProps {
  logout: () => void;
}
interface IProps extends IDispatchedProps, IStatedProps, RouteComponentProps {
  children: React.ReactNode;
  title: string;
  backButton: boolean;
}

const AppBarLayout = (props: IProps) => {
  const classes = useStyles();
  const goBack = () => {
    props.history.goBack();
    console.log("GOBACK");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar
          variant="dense"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: 10
          }}
        >
          <div className={classes.title_block}>
            {props.backButton && (
              <IconButton
                className={classes.backIcon}
                onClick={() => goBack()}
                color="inherit"
                href="/profile"
              >
                <ArrowBack />
              </IconButton>
            )}

            <Typography variant="h6" color="inherit">
              <Link className={classes.mainheader} to="/">
                Assembly Storage
              </Link>
            </Typography>
          </div>

          <div>
            <IconButton color="inherit" href="/help">
              <Help />
            </IconButton>
            <IconButton color="inherit" href="/profile">
              <Person />
            </IconButton>
            <IconButton color="inherit" href="/admin/users">
              <Settings />
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={e => props.logout()}
            >
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState): IStatedProps => ({});
export default connect<IStatedProps, IDispatchedProps, {}, IReduxState>(
  mapStateToProps,
  {
    logout: () => logout()
  }
)(withRouter(AppBarLayout));
