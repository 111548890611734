import actionCreatorFactory, { Action } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import appConfig from "../appConfig";
import { PermanentlySavedData } from "../models/permanentlySavedData";
import { bindAsyncAction } from "../utils/bindAsyncAction";
import { SagaIterator } from "redux-saga";
import ApiService from "../api/apiService";
import { call, takeEvery } from "redux-saga/effects";

// Store Models

export interface ISettingsBranch {
  cloud_token?: string;
  guid?: string;
  version?: string;
}

// Constants

export const moduleName = "emp";
const prefix = `${appConfig.appName}/${moduleName}`;

// Action Creators

const factory = actionCreatorFactory(prefix);

export const setPushToken = factory<string>("SETPUSHTOKEN");
export const setGuid = factory<string>("SETGUID");
export const setVersion = factory<string>("SETVERSION");

// reducer

export const createEmptySettings = (): ISettingsBranch => {
  const userState = localStorage.getItem("userState");
  if (userState) {
    const saved = JSON.parse(userState) as PermanentlySavedData;
    return saved.appSettings;
  } else {
    return {
      cloud_token: "",
      version: "",
      guid: ""
    };
  }
};

export const settingsReducer = reducerWithInitialState(createEmptySettings())
  .case(setPushToken, (state, cloudToken) => ({
    ...state,
    cloud_token: cloudToken
  }))
  .case(setGuid, (state, uuid) => ({
    ...state,
    guid: uuid
  }))
  .case(setVersion, (state, version) => ({
    ...state,
    version: version
  }));
