import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import { createStyles, Divider, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  changeSubscription,
  getProfile,
  getSubscriptions,
  IChangeSubscriptionPayload
} from "../../ducks/auth";
import TitlePageWrapper from "../../eventTemplateApp/components/titlePageWrapper";
import IUserRequestDto from "../../api/requests/user.dto";
import ProfileBlock from "./components/profileBlock";
import IUserSubscriptionDto from "../../api/responses/IUserSubscriptionDto";
import ToggleChannelButton, {
  ToggleChannelType
} from "../../eventTemplateApp/components/pages/event/components/toggleChannelButtons";
import ProgressIndicator from "../../components/progressIndicator";
import SubscriptionBlock from "./components/subscriptionBlock";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock_body: {
      marginBottom: 10
    },

    subscription: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
      justifyContent: "space-between"
    },
    subscription_title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    subscription_channels: {
      display: "flex",
      width: 160,
      justifyContent: "space-between"
    },
    subscription_divider: {
      marginBottom: 10
    }
  })
);

interface IStatedProps {
  isFetching: boolean;
  isError: boolean;
  profile: IUserRequestDto;
  subscriptions: IUserSubscriptionDto[];
  changeSubscriptionFetching: boolean;
  subscriptionsFetching: boolean;
}

interface IDispatchedProps {
  getProfile: () => void;
  getSubscription: () => void;
  changeSubscription: (payload: IChangeSubscriptionPayload) => void;
}

interface IProps extends IStatedProps, IDispatchedProps {}

const ProfilePage = (props: IProps) => {
  const classes = useStyles();
  const {
    profile: { username, displayName, email, position }
  } = props;
  const getTextBlock = (title: string, value: string) => (
    <>
      <Typography variant="caption">{title}</Typography>
      <Typography className={classes.textBlock_body} variant="body1">
        {value ? value : "-"}
      </Typography>
    </>
  );

  const subscriptionChange = (
    subscription: IUserSubscriptionDto,
    state: boolean,
    channelType: ToggleChannelType
  ) => {
    const payload: IChangeSubscriptionPayload = {
      isEmail: subscription.isEmail,
      isPush: subscription.isPush,
      isSms: subscription.isSms,
      streamId: subscription.streamId,
      subscriptionId: subscription.subscriptionId
    };
    switch (channelType) {
      case ToggleChannelType.email:
        payload.isEmail = state;
        break;

      case ToggleChannelType.push:
        payload.isPush = state;
        break;

      case ToggleChannelType.sms:
        payload.isSms = state;
        break;
    }

    props.changeSubscription(payload);
  };

  useEffect(() => {
    props.getProfile();
  }, []);

  useEffect(() => {
    if (props.profile.id) props.getSubscription();
  }, [props.profile]);

  return (
    <TitlePageWrapper
      isError={props.isError}
      isBlockingLoader={props.isFetching}
      title="Профиль"
    >
      <ProfileBlock title="Данные">
        {getTextBlock("ФИО", displayName)}
        {getTextBlock("Логин", username)}
        {getTextBlock("E-mail", email)}
        {getTextBlock("Должность", position)}
      </ProfileBlock>

      <ProfileBlock title="Подписки">
        <SubscriptionBlock
          subscriptionChange={subscriptionChange}
          subscriptions={props.subscriptions}
          subscriptionsFetching={props.subscriptionsFetching}
          changeSubscriptionFetching={props.changeSubscriptionFetching}
        />
      </ProfileBlock>
    </TitlePageWrapper>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: any): IStatedProps => ({
  isFetching: state.authBranch.userProfile.isFetching,
  isError: state.authBranch.userProfile.isError,
  profile: state.authBranch.userProfile.data,
  subscriptions: state.authBranch.subscriptions.data,
  subscriptionsFetching: state.authBranch.subscriptions.isFetching,
  changeSubscriptionFetching: state.authBranch.changeSubscriptionFetching
});

export default withRouter(
  connect<IStatedProps, IDispatchedProps, {}, IReduxState>(mapStateToProps, {
    getProfile: () => getProfile.started(),
    getSubscription: () => getSubscriptions.started({}),
    changeSubscription: changeSubscription.started
  })(ProfilePage)
);
