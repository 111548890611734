import * as React from "react";
import { connect } from "react-redux";
import {
  createOrEditStream,
  deleteStream,
  getStreams
} from "../../../ducks/stream";
import StreamDto from "../../../api/requests/stream.dto";
import { Fab, WithStyles, withStyles } from "@material-ui/core";
import StreamList from "./components/streamList";
import AddIcon from "@material-ui/icons/Add";
import fabButtonStyles, { FabButtonStylesKey } from "../../../variables/styles";
import { RouteComponentProps } from "react-router";
import TitlePageWrapper from "../../titlePageWrapper";
import PopupDialog from "../../dialog";
import AddStream from "./components/addStream";
import { IReduxState } from "../../../../reduxx/reducer";

export interface State {
  isAddOrEditStreamPopupOpen: boolean;
  editedStream: StreamDto;
}

interface DispatchedProps {
  getStreams: () => void;
  deleteStream: (stream: StreamDto) => void;
  createOrEditStream: (stream: StreamDto) => void;
}

interface StatedProps {
  streams: StreamDto[];
  isFetching: boolean;
  isError: boolean;
}

interface Props extends DispatchedProps, StatedProps, RouteComponentProps {}

class StreamPage extends React.Component<
  Props & WithStyles<FabButtonStylesKey>,
  State
> {
  constructor(props: Props & WithStyles<FabButtonStylesKey>) {
    super(props);
    this.state = {
      isAddOrEditStreamPopupOpen: false,
      editedStream: { title: "", description: "" }
    };
  }

  public render() {
    const { classes } = this.props;
    return (
      <TitlePageWrapper
        isLinearLoader={this.props.isFetching}
        title="Потоки"
        isError={this.props.isError}
      >
        <StreamList
          onEdit={this.onEdit}
          onSelect={this.onItemSelect}
          onDelete={this.onDelete}
          isFetching={this.props.isFetching}
          streams={this.props.streams}
        />

        <div style={{ position: "fixed" }}>
          <Fab
            onClick={this.openAddStreamPopup}
            variant="extended"
            className={classes.fab}
          >
            <AddIcon />
            Добавить
          </Fab>
        </div>

        <PopupDialog
          title="Новый поток"
          onClose={this.closeAddStreamPopup}
          saveClickHandler={this.onSave}
          saveButtonDisabled={
            !this.state.editedStream.title ||
            !this.state.editedStream.description
          }
          isOpen={this.state.isAddOrEditStreamPopupOpen}
        >
          <AddStream
            newOrEditStream={this.state.editedStream}
            onStreamChange={this.onEditedStreamChange}
          />
        </PopupDialog>
      </TitlePageWrapper>
    );
  }

  private onEditedStreamChange = (stream: StreamDto) => {
    this.setState({ editedStream: stream });
  };

  public componentDidMount(): void {
    this.props.getStreams();
  }

  private onSave = () => {
    this.props.createOrEditStream(this.state.editedStream);
  };

  private onEdit = (stream: StreamDto) => {
    this.setState({
      isAddOrEditStreamPopupOpen: true,
      editedStream: stream
    });
  };

  private closeAddStreamPopup = () => {
    this.setState({ isAddOrEditStreamPopupOpen: false });
  };

  private openAddStreamPopup = () => {
    this.setState({
      isAddOrEditStreamPopupOpen: true,
      editedStream: { title: "", description: "" }
    });
  };

  private onItemSelect = (streamId?: number) => {
    console.log(streamId);
    this.props.history.push(`/admin/templates/${streamId}/event`);
  };

  private onDelete = (stream: StreamDto) => {
    this.props.deleteStream(stream);
  };
}

const mapStateToProps = (state: IReduxState, ownProps: any): StatedProps => ({
  streams: state.eventTemplatesBranch.stream.getIn([
    "streams",
    "data"
  ]) as StreamDto[],
  isFetching: state.eventTemplatesBranch.stream.getIn([
    "streams",
    "isFetching"
  ]) as boolean,
  isError: state.eventTemplatesBranch.stream.getIn([
    "streams",
    "isError"
  ]) as boolean
});

export default connect(mapStateToProps, {
  getStreams: () => getStreams.started(),
  deleteStream: deleteStream.started,
  createOrEditStream: createOrEditStream.started
})(withStyles(fabButtonStyles)(StreamPage));
