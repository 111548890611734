import { RequestQueryBuilder } from "@nestjsx/crud-request";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError } from "../models/apiError";
import IAssemblyDto from "./responses/IAssemblyDto";
import { Platform } from "../models/platform";
import IProjectDto from "./responses/IProjectDto";
import IBundleDto from "./responses/IBundleDto";
import { ILoginPayload } from "../ducks/auth";
import { getToken, setToken } from "../utils/authUtils";
import ILinkDto from "./responses/ILinkDto";
import INewsItemDto from "./responses/INewsItemDto";
import BaseApiService from "./base/baseApiService";
import ILoginPayloadResponseDto from "./responses/ILoginPayloadResponseDto";

const apiUrls = {
  assembly: "/api/assembly",
  project: "/api/project",
  bundle: "/api/bundle",
  auth: "/api/auth",
  link: "/api/link",
  download: "/api/download",
  news: "/api/news",
  user: "/api/user"
};

interface IRequestOptions {
  token?: string;
}

export default class ApiService extends BaseApiService {
  constructor() {
    super();
  }

  public async getAssemblies(
    platform: Platform,
    projectId?: string,
    bundleId?: string
  ): Promise<IAssemblyDto[]> {
    const url = `${apiUrls.assembly}`;
    let queryBuilder = RequestQueryBuilder.create();
    queryBuilder = queryBuilder.select([
      "id",
      "buildNumber",
      "versionName",
      "versionCode",
      "branch",
      "bundleId",
      "size",
      "path",
      "plistPath",
      "fileName",
      "createdAt",
      "updatedAt"
    ]);
    queryBuilder = queryBuilder.setJoin({ field: "bundle" }).setFilter({
      field: "bundle.platform",
      operator: "$eq",
      value: platform
    });
    if (projectId) {
      queryBuilder = queryBuilder
        .setJoin({ field: "bundle.project" })
        .setFilter({
          field: "bundle.project.id",
          operator: "$eq",
          value: projectId
        });
    }
    if (bundleId) {
      queryBuilder = queryBuilder.setFilter({
        field: "bundle.id",
        operator: "$eq",
        value: bundleId
      });
    }
    queryBuilder = queryBuilder.sortBy({ field: "createdAt", order: "DESC" });

    const query = queryBuilder.query();
    return await this.getRequest<IAssemblyDto[]>(`${url}?${query}`);
  }

  public async getProjects(): Promise<IProjectDto[]> {
    const url = `${apiUrls.project}`;
    return await this.getRequest<IProjectDto[]>(url);
  }

  public async login(
    payload: ILoginPayload
  ): Promise<ILoginPayloadResponseDto> {
    const url = `${apiUrls.auth}/login`;
    const result = await this.postRequest<ILoginPayloadResponseDto>(
      url,
      payload
    );
    if (result) {
      setToken(result.jwtToken);
    }
    return result;
  }

  public async getProfile(): Promise<string> {
    const url = `${apiUrls.auth}`;
    const result = await this.getRequest<string>(url);

    return result;
  }

  public async getBundles(): Promise<IBundleDto[]> {
    const url = `${apiUrls.bundle}`;
    let queryBuilder = RequestQueryBuilder.create();
    queryBuilder = queryBuilder.setJoin({ field: "bundle" });
    return await this.getRequest<IBundleDto[]>(
      `${url}?${queryBuilder.query()}`
    );
  }
  public async getAssembly(id: number): Promise<IAssemblyDto> {
    const url = `${apiUrls.assembly}`;
    let queryBuilder = RequestQueryBuilder.create();
    queryBuilder = queryBuilder.setJoin({ field: "bundle" });
    const query = queryBuilder.query();
    return await this.getRequest<IAssemblyDto>(`${url}/${id}?${query}`);
  }

  public async getLink(id: string): Promise<ILinkDto> {
    const url = `${apiUrls.link}`;
    let queryBuilder = RequestQueryBuilder.create();
    queryBuilder = queryBuilder
      .setJoin({ field: "assembly" })
      .setJoin({ field: "assembly.bundle" })
      .setJoin({ field: "assembly.bundle.project" });
    const query = queryBuilder.query();
    return await this.getRequest<ILinkDto>(`${url}/${id}?${query}`);
  }

  public async getLinkDownload(id: string) {
    const url = `${apiUrls.download}`;
    return await this.getRequest<string>(`${url}/link/${id}`);
  }

  public async createLink(title: string, assemblyId: number) {
    const url = `${apiUrls.link}`;
    return await this.postRequest<ILinkDto>(url, { title, assemblyId });
  }

  public async getTopNews(count: number) {
    const url = `${apiUrls.news}`;
    return await this.getRequest<INewsItemDto>(`${url}/list/${count}`);
  }
}
