import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import UserTab from "./tabs/userTab";
import { createStyles } from "@material-ui/core";
import ProjectTab from "./tabs/projectTab";
import BundleTab from "./tabs/bundleTab";
import { Link, Route, Switch } from "react-router-dom";
import AssemblyTab from "./tabs/assemblyTab";
import EventServiceApp from "../../eventTemplateApp/App";
import { IReduxState } from "../../reduxx/reducer";
import { getProjects } from "../../ducks/assembly";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import IProjectDto from "../../api/responses/IProjectDto";
import {
  RouteForTab,
  RouteAdminTab,
  RouteTabItem
} from "./components/routeTab";
import { getAllEvents, getEvents } from "../../eventTemplateApp/ducks/event";
import { EventDto } from "../../eventTemplateApp/api/requests/event.dto";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      height: "100%"
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  })
);

interface IStatedProps {
  projects: IProjectDto[];
  events: EventDto[];
}

interface IDispatchedProps {
  getProjects: () => void;
  getEvents: () => void;
}

interface IProps extends IStatedProps, IDispatchedProps, RouteComponentProps {}

function AdminPage(props: IProps) {
  const projects = props.projects;
  const classes = useStyles();

  useEffect(() => {
    props.getProjects();
    props.getEvents();
  }, []);
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        indicatorColor="secondary"
        value={props.location.pathname}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <RouteTabItem label="Пользователи" value={RouteAdminTab.users} />
        <RouteTabItem label="Проекты" value={RouteAdminTab.projects} />
        <RouteTabItem label="Бандлы" value={RouteAdminTab.bundles} />
        <RouteTabItem label="Сборки" value={RouteAdminTab.assemblies} />
        <RouteTabItem label="Шаблоны событий" value={RouteAdminTab.templates} />
        <RouteTabItem label="Интеграции" value={RouteAdminTab.integration} />
        <RouteTabItem label="Настройки" value={RouteAdminTab.settings} />
      </Tabs>
      <Switch>
        <RouteForTab label="Пользователи" path={RouteAdminTab.users}>
          <UserTab />
        </RouteForTab>
        <RouteForTab label="Проекты" path={RouteAdminTab.projects}>
          <ProjectTab events={props.events} />
        </RouteForTab>
        <RouteForTab label="Бандлы" path={RouteAdminTab.bundles}>
          <BundleTab projects={projects} />
        </RouteForTab>
        <RouteForTab label="Сборки" path={RouteAdminTab.assemblies}>
          <AssemblyTab />
        </RouteForTab>
        <RouteForTab label="Шаблоны" path={RouteAdminTab.templates}>
          <EventServiceApp />
        </RouteForTab>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state: IReduxState): IStatedProps => ({
  projects: state.assemblyBranch.projectsData.data,
  events: state.eventTemplatesBranch.event.getIn(["allEvents", "data"])
});

export default connect<IStatedProps, IDispatchedProps, {}, IReduxState>(
  mapStateToProps,
  {
    getProjects: () => getProjects.started(),
    getEvents: () => getAllEvents.started()
  }
)(withRouter(AdminPage));
