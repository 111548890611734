import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "./App.css";
import ApiErrorProvider from "./components/providers/apiErrorProvider";
import { configuredStore, history } from "./reduxx";
import Routes from "./routes/routes";
import theme from "./theme";
import { MuiThemeProvider } from "@material-ui/core";
import moment from "moment";
import "moment/locale/ru";
import PushProvider from "./components/providers/pushProvider";
moment.locale("ru");

require("dotenv").config();

function App(): JSX.Element {
  return (
    <Provider store={configuredStore}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ApiErrorProvider>
            <PushProvider>
              <Routes />
            </PushProvider>
          </ApiErrorProvider>
        </ConnectedRouter>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
