import React from "react";
import {
  createStyles,
  Divider,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      marginBottom: 20
    },
    title: {
      marginBottom: 10
    },
    title_with_divider: {
      marginBottom: 10
    }
  })
);

interface IProps {
  title: string;
  children: React.ReactNode;
}
const ProfileBlock = (props: IProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.block}>
      <div className={classes.title_with_divider}>
        <Typography className={classes.title} variant="h3">
          {props.title}
        </Typography>
        <Divider />
      </div>

      {props.children}
    </Paper>
  );
};

export default ProfileBlock;
