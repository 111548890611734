import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import {
  createLink,
  getAssembly,
  ICreateLink,
  IGetAssembly
} from "../../ducks/assembly";
import IAssemblyDto from "../../api/responses/IAssemblyDto";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AddIcon from "@material-ui/icons/Add";
import convertersUtils from "../../utils/convertersUtils";
import AssemblyAttributes from "./components/assemblyAttributes";
import ProgressIndicator from "../../components/progressIndicator";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1)
    },
    header: {
      padding: 10,
      marginBottom: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    content: {
      padding: 10
    },
    leftPanel: {
      paddingRight: 10,
      display: "flex",
      flexDirection: "column",
      alignItems: "right"
    },
    tableContainer: {},
    table: {
      width: "100%"
    }
  })
);

interface RouteParams {
  id: string;
}

interface IStatedProps {
  assembly: IAssemblyDto;
  isFetching: boolean;
}

interface IDispatchedProps {
  getAssembly: (request: IGetAssembly) => void;
  createLink: (link: ICreateLink) => void;
}

interface IProps
  extends IStatedProps,
    IDispatchedProps,
    RouteComponentProps<RouteParams> {}

const AssemblyInfoPage = (props: IProps) => {
  const classes = useStyles();
  const id = parseInt(props.match.params.id);
  const [createLinkDialogOpen, setCreateLinkDialogOpen] = useState(false);
  const [newLinkTitle, setNewLinkTitle] = useState("");
  const closeDialog = () => {
    setCreateLinkDialogOpen(false);
  };
  const openDialog = () => {
    setCreateLinkDialogOpen(true);
  };

  const onTitleChange = (event: any) => {
    setNewLinkTitle(event.target.value);
  };

  const createLink = () => {
    props.createLink({ title: newLinkTitle, assemblyId: props.assembly.id });
    closeDialog();
  };
  useEffect(() => {
    props.getAssembly({ id });
  }, []);

  return (
    <>
      {props.isFetching ? (
        <ProgressIndicator />
      ) : (
        <>
          <Paper className={classes.header}>
            <div>
              <Typography variant="overline">
                {props.assembly.bundle.platform}
              </Typography>
              <Typography variant="h1">
                {props.assembly?.versionName}{" "}
                {`(${props.assembly.versionCode})`}
              </Typography>
              <Typography>
                {convertersUtils.convertDateTimeToFormat(
                  props.assembly.createdAt,
                  "DD MMMM YYYY HH:mm"
                )}
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={openDialog}
                startIcon={<AddIcon />}
              >
                Создать новую ссылку
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                href={`/api/download/assembly/${props.assembly.id}`}
                startIcon={<ArrowDownwardIcon />}
              >
                Скачать артефакт
              </Button>
            </div>
          </Paper>
          <Paper className={classes.content}>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <div className={classes.leftPanel}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Название</TableCell>
                          <TableCell>Количество скачиваний</TableCell>
                          <TableCell>Время создания</TableCell>
                          <TableCell>Действия</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.assembly.links.map(link => (
                          <TableRow key={link.id}>
                            <TableCell component="th" scope="row">
                              {link.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {link.downloadCount}
                            </TableCell>
                            <TableCell>
                              {convertersUtils.convertDateTimeToFormat(
                                link.createdAt,
                                "DD MMM YYYY HH:mm"
                              )}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                href={`/download/${link.id}`}
                              >
                                <OpenInBrowserIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              <Grid item={true} xs={6}>
                <AssemblyAttributes
                  changes={props.assembly.changes}
                  attributes={props.assembly.attributes}
                />
              </Grid>
            </Grid>
          </Paper>
          <Dialog
            open={createLinkDialogOpen}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Создание новой ссылки
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Ссылки нужны для дифференциации скачиваний от тестеров и от
                заказчиков. Вы можете создать неограниченное количество ссылок,
                которые все будут ссылаться на одну сборку
              </DialogContentText>
              <DialogContentText>
                Введите название ссылки или оставьте поле пустым (имя в этом
                случае присвоится автоматически)
              </DialogContentText>
              <TextField
                autoFocus
                onChange={onTitleChange}
                margin="dense"
                id="label"
                label="Название"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={createLink}>
                Создать
              </Button>
              <Button onClick={closeDialog} color="primary">
                Отменить
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: any): IStatedProps => ({
  assembly: state.assemblyBranch.selectedAssembly.data,
  isFetching: state.assemblyBranch.selectedAssembly.isFetching
});

export default withRouter(
  connect<IStatedProps, IDispatchedProps, {}, IReduxState>(mapStateToProps, {
    getAssembly: getAssembly.started,
    createLink: createLink.started
  })(AssemblyInfoPage)
);
