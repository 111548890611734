import React from "react";
import { Switch, Route } from "react-router-dom";
import MainPage from "../pages/main/mainPage";
import AssemblyInfoPage from "../pages/assemblyInfo/assemblyInfoPage";
import LoginPage from "../pages/login/loginPage";
import DownloadPage from "../pages/download/downloadPage";

import { requireAuth } from "../components/decorators/requireAuth";
import RouteWithLayout from "../components/containers/routerWithLayout";
import MainContainer from "../components/containers/appBarLayout";
import AdminPage from "../pages/admin/adminPage";
import ProfilePage from "../pages/profile/profilePage";
import HelpPage from "../pages/help/helpPage";

const Routes = () => (
  <Switch>
    <RouteWithLayout
      title="Главный"
      component={requireAuth(MainPage)}
      exact={true}
      layout={MainContainer}
      path="/"
    />
    <RouteWithLayout
      title="Инфо"
      component={requireAuth(AssemblyInfoPage)}
      exact={false}
      layout={MainContainer}
      path="/assembly/:id"
    />
    <RouteWithLayout
      title="Профиль"
      component={ProfilePage}
      exact={false}
      layout={MainContainer}
      path="/profile"
    />
    <RouteWithLayout
      title="Помощь"
      component={HelpPage}
      exact={false}
      layout={MainContainer}
      path="/help"
    />
    <RouteWithLayout
      title="Инфо"
      component={DownloadPage}
      exact={false}
      layout={null}
      path="/download/:id"
    />

    <RouteWithLayout
      title="Админ"
      component={AdminPage}
      exact={false}
      layout={MainContainer}
      path="/admin"
    />

    <Route exact={true} path="/login" component={LoginPage}></Route>
  </Switch>
);

export default Routes;
