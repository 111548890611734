import moment from "moment";

export default class ConvertersUtils {
  public static formatSize(length: number) {
    let i = 0,
      type = ["б", "Кб", "Мб", "Гб", "Тб", "Пб"];
    while ((length / 1000) | 0 && i < type.length - 1) {
      length /= 1024;
      i++;
    }
    return length.toFixed(2) + " " + type[i];
  }

  public static convertDateTimeToFormat(date: string, format: string) {
    return moment(date).format(format);
  }
}
