import React from "react";

const HelpPage = () => {
  return (
    <iframe
      style={{ width: "100%", height: "100%", border: "none" }}
      src="/docs/"
    >
      Ваш браузер не поддерживает плавающие фреймы!
    </iframe>
  );
};

export default HelpPage;
