import { LoadingData } from "../models/loadingData";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import appConfig from "../appconf";
import { Record } from "immutable";
import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, delay } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import StreamDto from "../api/requests/stream.dto";
import EventApiService from "../api/eventApiService";
import { ApiError } from "../models/apiError";

export const moduleName = "stream";
const prefix = `${appConfig.appName}/${moduleName}`;
// State model
export interface StreamModel {
  streams: LoadingData<StreamDto[]>;
}

// Action Creators

const factory = actionCreatorFactory(prefix);

export const getStreams = factory.async<void, StreamDto[], ApiError>(
  "GET_STREAMS"
);

export const deleteStream = factory.async<StreamDto, boolean, ApiError>(
  "DELETE_STREAM"
);

export const createOrEditStream = factory.async<StreamDto, StreamDto, ApiError>(
  "CREATE_OR_EDIT_STREAM"
);

const createEmpty = Record<StreamModel>({
  streams: { isFetching: false, data: [], isError: false }
});

export const streamReducer = reducerWithInitialState(createEmpty())
  .case(getStreams.started, state =>
    state.setIn(["streams", "isFetching"], true)
  )
  .case(getStreams.failed, state =>
    state
      .setIn(["streams", "isFetching"], false)
      .setIn(["streams", "isError"], true)
  )
  .case(getStreams.done, (state, payload) => {
    return state
      .setIn(["streams", "isFetching"], false)
      .setIn(["streams", "data"], payload.result);
  })
  //deleteStream
  .case(deleteStream.started, state =>
    state.setIn(["streams", "isFetching"], true)
  )
  .case(deleteStream.failed, state =>
    state.setIn(["streams", "isFetching"], false)
  )
  .case(deleteStream.done, (state, payload) =>
    state.setIn(["streams", "isFetching"], false)
  )
  //updateOrCreateStream
  .case(createOrEditStream.started, state =>
    state.setIn(["streams", "isFetching"], true)
  )
  .case(createOrEditStream.failed, state =>
    state.setIn(["streams", "isFetching"], false)
  )
  .case(createOrEditStream.done, (state, payload) =>
    state.setIn(["streams", "isFetching"], false)
  );

const getStreamWorker = bindAsyncAction(getStreams, {
  skipStartedAction: true
})(function*() {
  //TODO костыль на добавление небольшой задержки, чтобы показать скелетон
  yield delay(500);
  const apiService = new EventApiService();
  const response = yield call([apiService, apiService.getStreams]);
  return response;
});

const deleteStreamWorker = bindAsyncAction(deleteStream, {
  skipStartedAction: true
})(function*(stream: StreamDto) {
  const apiService = new EventApiService();
  const response = yield call([apiService, apiService.deleteStream], stream);
  yield put(getStreams.started());
  return response;
});

const createOrEditWorker = bindAsyncAction(createOrEditStream, {
  skipStartedAction: true
})(function*(stream: StreamDto) {
  const apiService = new EventApiService();
  let response;
  if (stream.id) {
    //update
    response = yield call([apiService, apiService.updateStream], stream);
  } else {
    //create
    response = yield call([apiService, apiService.createStream], stream);
  }
  yield put(getStreams.started());
  return response;
});

export function* saga(): SagaIterator {
  yield takeEvery<Action<void>>(getStreams.started, action =>
    getStreamWorker()
  );
  yield takeEvery<Action<StreamDto>>(deleteStream.started, action =>
    deleteStreamWorker(action.payload)
  );
  yield takeEvery<Action<StreamDto>>(createOrEditStream.started, action =>
    createOrEditWorker(action.payload)
  );
}
