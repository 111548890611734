import React, { useEffect } from "react";
import IUserDto from "../../../api/responses/IUserDto";
import CrudTableContainer from "../components/crudTable";
import SubscriptionDetailPanel from "../components/subscriptionDetailPanel";

interface IProps {}

const UserTab = (props: IProps) => {
  const UserTable = CrudTableContainer<IUserDto>();
  return (
    <UserTable
      entityName="user"
      title="Пользователи"
      columns={[
        { title: "Id", field: "id", editable: "never", hidden: true },
        { title: "Логин", field: "username", editable: "never" },
        { title: "Email", field: "email" },
        { title: "Фио", field: "displayName" },
        { title: "Должность", field: "position" },
        { title: "Админ?", field: "isAdmin", type: "boolean" }
      ]}
      detailPanel={rowData => {
        return <SubscriptionDetailPanel user={rowData} />;
      }}
    />
  );
};

export default UserTab;
