import React, { useEffect } from "react";
import {
  Button,
  createStyles,
  Grid,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import {
  getLinkData,
  getLinkDownloadUrl,
  IGetLink
} from "../../ducks/assembly";
import ILinkDto from "../../api/responses/ILinkDto";
import convertersUtils from "../../utils/convertersUtils";
import SharePanel from "./components/sharePanel";
import AddIcon from "@material-ui/icons/Add";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ShareProperties from "../../components/decorators/shareProperties";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%"
    },
    projectTitle: {
      marginBottom: 15
    },
    versionTitle: {
      marginBottom: 15
    },
    qrPanel: {
      marginTop: 20,
      marginBottom: 20
    },
    downloadButton: {
      marginBottom: 20
    }
  })
);

interface RouteParams {
  id: string;
}

interface IStatedProps {
  link: ILinkDto;
  isFetching: boolean;
  url: string;
}

interface IDispatchedProps {
  getLink: (request: IGetLink) => void;
  getUrl: (request: IGetLink) => void;
}

interface IProps
  extends IStatedProps,
    IDispatchedProps,
    RouteComponentProps<RouteParams> {}

const DownloadPage = (props: IProps) => {
  const classes = useStyles();
  const id = props.match.params.id;
  const qrLink = `/api/download/qr/link/${props.link.id}`;
  const qrShareLink = `/api/download/qr/link/image/${props.link.id}`;
  const shareTitle = `${
    props.link.assembly.bundle.project.title
  } (${props.link.assembly.bundle.platform.toString().toUpperCase()})`;
  useEffect(() => {
    props.getLink({ id });
    props.getUrl({ id });
  }, []);
  return (
    <div className={classes.content}>
      <Typography variant="h1" className={classes.projectTitle}>
        {props.link.assembly.bundle.project.title} (
        {props.link.assembly.bundle.platform})
      </Typography>
      <Typography variant="h2">{`Версия: ${props.link.assembly.versionName} (${props.link.assembly.versionCode})`}</Typography>

      <Typography className={classes.versionTitle} variant="subtitle2">
        {props.link.assembly.fileName}
      </Typography>

      <Typography variant="subtitle1">
        {convertersUtils.convertDateTimeToFormat(
          props.link.createdAt,
          "DD MMMM YYYY HH:mm"
        )}
      </Typography>

      <Paper className={classes.qrPanel}>
        {props.link && <img width={400} height={400} src={qrLink} />}
      </Paper>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.downloadButton}
        href={props.url}
        startIcon={<ArrowDownwardIcon />}
      >
        Скачать
      </Button>
      <SharePanel title={shareTitle} />
      <ShareProperties
        title={shareTitle}
        description={`Версия: ${props.link.assembly.versionName} (${props.link.assembly.versionCode}) - ${props.link.assembly.fileName}`}
        imageUrl={`${window.location.origin}${qrShareLink}`}
        date={convertersUtils.convertDateTimeToFormat(
          props.link.assembly.createdAt,
          "DD MMMM YYYY HH:mm"
        )}
        url={window.location.href}
      />
    </div>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: any): IStatedProps => ({
  link: state.assemblyBranch.selectedLink.data,
  isFetching: state.assemblyBranch.selectedAssembly.isFetching,
  url: state.assemblyBranch.selectedUrl.data
});

export default withRouter(
  connect<IStatedProps, IDispatchedProps, {}, IReduxState>(mapStateToProps, {
    getLink: getLinkData.started,
    getUrl: getLinkDownloadUrl.started
  })(DownloadPage)
);
