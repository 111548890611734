// Store Models
import { SagaIterator } from "redux-saga";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { ApiError } from "../models/apiError";
import IAssemblyDto from "../api/responses/IAssemblyDto";
import { bindAsyncAction } from "../utils/bindAsyncAction";
import { call, put, takeEvery } from "redux-saga/effects";
import ApiService from "../api/apiService";
import { ILoadingData } from "../models/loadingData";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { Platform } from "../models/platform";
import IProjectDto from "../api/responses/IProjectDto";
import IBundleDto from "../api/responses/IBundleDto";
import { IReduxState } from "../reduxx/reducer";
import { createSelector } from "reselect";
import ILinkDto from "../api/responses/ILinkDto";
import appConfig from "../appConfig";

export const moduleName = "assembly";
const prefix = `${appConfig.appName}/${moduleName}`;
const factory = actionCreatorFactory(prefix);

export interface IGetAssemblies {
  platform: Platform;
  projectId?: string;
  bundleId?: string;
}

export interface IGetAssembly {
  id: number;
}

export interface IGetLink {
  id: string;
}

export interface ICreateLink {
  title: string;
  assemblyId: number;
}

export interface IAssembliesFilter {
  selectedPlatform: Platform;
  selectedProjectId?: string;
  selectedBundleId?: string;
}

export interface IAssembliesFilterChange {
  oldFilter: IAssembliesFilter;
  newFilter: IAssembliesFilter;
}

export interface IAssemblyStateBranch {
  assembliesData: ILoadingData<IAssemblyDto[]>;
  assembliesFilter: IAssembliesFilter;
  selectedAssembly: ILoadingData<IAssemblyDto>;
  projectsData: ILoadingData<IProjectDto[]>;
  bundlesData: ILoadingData<IBundleDto[]>;
  selectedLink: ILoadingData<ILinkDto>;
  selectedUrl: ILoadingData<string>;
}

export const createEmpty = (): IAssemblyStateBranch => {
  return {
    assembliesData: { isFetching: false, isError: false, data: [] },
    projectsData: { isFetching: false, isError: false, data: [] },
    bundlesData: { isFetching: false, isError: false, data: [] },
    assembliesFilter: { selectedPlatform: Platform.ANDROID },
    selectedAssembly: {
      isFetching: false,
      isError: false,
      data: {
        id: 0,
        size: 0,
        versionCode: "",
        buildNumber: "",
        versionName: "",
        branch: "",
        bundleId: "",
        links: [],
        bundle: {
          platform: Platform.ANDROID,
          project: { id: "", title: "", iconUrl: "", eventUploadId: 0 },
          id: "",
          description: ""
        },
        path: "",
        fileName: "",
        createdAt: "",
        updatedAt: ""
      }
    },
    selectedLink: {
      isFetching: false,
      isError: false,
      data: {
        id: "",
        createdAt: "",
        updatedAt: "",
        downloadCount: 0,
        title: "",
        assembly: {
          id: 0,
          size: 0,
          versionCode: "",
          buildNumber: "",
          versionName: "",
          branch: "",
          bundleId: "",
          links: [],
          bundle: {
            platform: Platform.ANDROID,
            project: { id: "", title: "", iconUrl: "", eventUploadId: 0 },
            id: "",
            description: ""
          },
          path: "",
          fileName: "",
          createdAt: "",
          updatedAt: ""
        }
      }
    },
    selectedUrl: {
      isError: false,
      isFetching: false,
      data: ""
    }
  };
};
export const getAssemblies = factory.async<
  IGetAssemblies,
  IAssemblyDto[],
  ApiError
>("GET_ASSEMBLIES");

export const getAssembly = factory.async<IGetAssembly, IAssemblyDto, ApiError>(
  "GET_ASSEMBLY"
);

export const createLink = factory.async<ICreateLink, ILinkDto, ApiError>(
  "CREATE_LINK"
);

export const getLinkData = factory.async<IGetLink, ILinkDto, ApiError>(
  "GET_LINK"
);

export const getProjects = factory.async<void, IProjectDto[], ApiError>(
  "GET_PROJECTS"
);

export const getBundles = factory.async<void, IBundleDto[], ApiError>(
  "GET_BUNDLES"
);

export const setFilter = factory<IAssembliesFilterChange>(
  "SET_ASSEMBLY_FILTER"
);

export const getLinkDownloadUrl = factory.async<IGetLink, string, ApiError>(
  "GET_LINK_DOWNLOAD_URL"
);

export const assemblyReducer = reducerWithInitialState(createEmpty())
  .case(getAssemblies.started, state => ({
    ...state,
    assembliesData: { ...state.assembliesData, isFetching: true }
  }))
  .case(getAssemblies.failed, state => ({
    ...state,
    assembliesData: { ...state.assembliesData, isFetching: false }
  }))
  .case(getAssemblies.done, (state, payload) => ({
    ...state,
    assembliesData: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(getProjects.started, state => ({
    ...state,
    projectsData: { ...state.projectsData, isFetching: true }
  }))
  .case(getProjects.failed, state => ({
    ...state,
    projectsData: { ...state.projectsData, isFetching: false }
  }))
  .case(getProjects.done, (state, payload) => ({
    ...state,
    projectsData: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(getBundles.started, state => ({
    ...state,
    bundlesData: { ...state.bundlesData, isFetching: true }
  }))
  .case(getBundles.failed, state => ({
    ...state,
    bundlesData: { ...state.bundlesData, isFetching: false }
  }))
  .case(getBundles.done, (state, payload) => ({
    ...state,
    bundlesData: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(setFilter, (state, payload) => ({
    ...state,
    assembliesFilter: payload.newFilter
  }))

  .case(getAssembly.started, state => ({
    ...state,
    selectedAssembly: { ...state.selectedAssembly, isFetching: true }
  }))
  .case(getAssembly.failed, state => ({
    ...state,
    selectedAssembly: { ...state.selectedAssembly, isFetching: false }
  }))
  .case(getAssembly.done, (state, payload) => ({
    ...state,
    selectedAssembly: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(getLinkData.started, state => ({
    ...state,
    selectedLink: { ...state.selectedLink, isFetching: true }
  }))
  .case(getLinkData.failed, state => ({
    ...state,
    selectedLink: { ...state.selectedLink, isFetching: false }
  }))
  .case(getLinkData.done, (state, payload) => ({
    ...state,
    selectedLink: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }))
  .case(getLinkDownloadUrl.started, state => ({
    ...state,
    selectedUrl: { ...state.selectedUrl, isFetching: true }
  }))
  .case(getLinkDownloadUrl.failed, state => ({
    ...state,
    selectedUrl: { ...state.selectedUrl, isFetching: false }
  }))
  .case(getLinkDownloadUrl.done, (state, payload) => ({
    ...state,
    selectedUrl: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }));

const getAssembliesWorker = bindAsyncAction(getAssemblies)(function*(
  param: Action<IGetAssemblies>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call(
    [apiService, apiService.getAssemblies],
    param.payload.platform,
    param.payload.projectId,
    param.payload.bundleId
  );
  return response;
});

const getProjectsWorker = bindAsyncAction(getProjects)(
  function*(): SagaIterator {
    const apiService = new ApiService();
    const response = yield call([apiService, apiService.getProjects]);
    return response;
  }
);

const getBundlesWorker = bindAsyncAction(getBundles)(function*(): SagaIterator {
  const apiService = new ApiService();
  const response = yield call([apiService, apiService.getBundles]);
  return response;
});

const getAssemblyWorker = bindAsyncAction(getAssembly)(function*(
  param: Action<IGetAssembly>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call(
    [apiService, apiService.getAssembly],
    param.payload.id
  );
  return response;
});

const getLinkDataWorker = bindAsyncAction(getLinkData)(function*(
  param: Action<IGetLink>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call(
    [apiService, apiService.getLink],
    param.payload.id
  );
  return response;
});

const getLinkDownloadUrlWorker = bindAsyncAction(getLinkDownloadUrl)(function*(
  param: Action<IGetLink>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call(
    [apiService, apiService.getLinkDownload],
    param.payload.id
  );
  return response;
});

const createLinkWorker = bindAsyncAction(createLink)(function*(
  param: Action<ICreateLink>
): SagaIterator {
  const apiService = new ApiService();
  const response = yield call(
    [apiService, apiService.createLink],
    param.payload.title,
    param.payload.assemblyId
  );
  if (!param.payload.title) {
    param.payload.title = "Ссылка без названия";
  }
  yield put(getAssembly.started({ id: param.payload.assemblyId }));
  return response;
});

function* setFilterWorker(param: IAssembliesFilterChange) {
  const currentFilters = param.oldFilter;
  const newFilters = param.newFilter;

  yield put(
    getAssemblies.started({
      platform: newFilters.selectedPlatform,
      projectId: newFilters.selectedProjectId,
      bundleId: newFilters.selectedBundleId
    })
  );
}

const getFiltersSelector = (state: IReduxState) =>
  state.assemblyBranch.assembliesFilter;

const getBundlesSelector = (state: IReduxState) =>
  state.assemblyBranch.bundlesData.data;

export const getBundlesByProject = createSelector(
  getFiltersSelector,
  getBundlesSelector,
  (filters, bundles) => {
    let filteredBundles = bundles;
    if (filters.selectedProjectId) {
      filteredBundles = filteredBundles.filter(
        bundle => bundle.project.id === filters.selectedProjectId
      );
    }

    if (filters.selectedPlatform) {
      filteredBundles = filteredBundles.filter(
        bundle => bundle.platform === filters.selectedPlatform
      );
    }

    return filteredBundles;
  }
);

export function* saga(): SagaIterator {
  yield takeEvery<Action<IGetAssemblies>>(
    getAssemblies.started,
    getAssembliesWorker
  );
  yield takeEvery<Action<void>>(getProjects.started, getProjectsWorker);
  yield takeEvery<Action<void>>(getBundles.started, getBundlesWorker);

  yield takeEvery(setFilter, action => setFilterWorker(action.payload));

  yield takeEvery<Action<IGetAssembly>>(getAssembly.started, getAssemblyWorker);

  yield takeEvery<Action<IGetLink>>(getLinkData.started, getLinkDataWorker);

  yield takeEvery<Action<ICreateLink>>(createLink.started, createLinkWorker);
  yield takeEvery<Action<IGetLink>>(
    getLinkDownloadUrl.started,
    getLinkDownloadUrlWorker
  );
}
