import * as React from "react";
import StreamDto from "../../../../api/requests/stream.dto";
import { TextField } from "@material-ui/core";

interface Props {
  newOrEditStream: StreamDto;
  onStreamChange: (stream: StreamDto) => void;
}

export default class AddStream extends React.Component<Props> {
  public render() {
    return (
      <div>
        <TextField
          required
          value={this.props.newOrEditStream.title}
          id="standard-required"
          onChange={this.handleChange("title")}
          label="Имя потока"
          fullWidth={true}
          margin="normal"
        />
        <TextField
          required
          id="standard-required"
          onChange={this.handleChange("description")}
          value={this.props.newOrEditStream.description}
          label="Описание потока"
          multiline={true}
          fullWidth={true}
          margin="normal"
        />
      </div>
    );
  }

  private handleChange = (name: keyof StreamDto) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const changed = Object.assign({}, this.props.newOrEditStream, {
      [name]: event.target.value
    });

    this.props.onStreamChange(changed);
  };
}
