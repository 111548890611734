import * as React from "react";
import { Snackbar, Theme, createStyles } from "@material-ui/core";
import { NotificationsNone } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      minWidth: 400,
      borderStyle: "solid",
      background: "white",
      borderColor: theme.palette.primary.light
    }
  })
);
interface Props extends RouteComponentProps {
  text: string;
  title: string;
  isOpen: boolean;
  clickAction: string;
  icon: string;
}

const NotificationSnack = (props: Props) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setOpen(props.isOpen);
  }, [props]);
  const onNotificationClick = () => {
    window.location.href = props.clickAction;
  };
  return (
    <Snackbar
      open={open}
      onClick={onNotificationClick}
      autoHideDuration={111000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      onClose={() => setOpen(false)}
    >
      <Alert
        className={classes.snackbar}
        iconMapping={{
          success: <img style={{ width: 50 }} src={props.icon}></img>
        }}
        onClose={() => setOpen(false)}
        severity="success"
      >
        <AlertTitle>{props.title}</AlertTitle>
        {props.text}
      </Alert>
    </Snackbar>
  );
};

export default withRouter(NotificationSnack);
