import * as React from "react";
import {
  createStyles,
  IconButton,
  List,
  ListItemSecondaryAction,
  makeStyles,
  Theme
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { grey } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import { EventDto } from "../../../../api/requests/event.dto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "@material-ui/lab/Skeleton";
import { faRobot } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      backgroundColor: theme.palette.background.paper
    },
    paper: {
      width: "100%",
      padding: 5,
      marginBottom: 10
    },
    orangeAvatar: {
      margin: 10,
      color: "#fff",
      backgroundColor: theme.palette.secondary.main
    },
    greyAvatar: {
      margin: 10,
      color: "#fff",
      backgroundColor: grey[500]
    },
    skeletonAvatar: {
      margin: 10
    }
  })
);

type EventItemProps = {
  event: EventDto;
  onSelect: (eventId?: number) => void;
  onDelete: (event: EventDto) => void;
};

type EventListProps = {
  events: EventDto[];
  isFetching: boolean;
  onSelect: (eventId?: number) => void;
  onDelete: (event: EventDto) => void;
};

const EventItem: React.FunctionComponent<EventItemProps> = props => {
  const styles = useStyles();
  const { event, onSelect } = props;
  return (
    <Paper className={styles.paper}>
      <ListItem onClick={e => onSelect(event.eventId)}>
        <ListItemAvatar>
          <ListItemAvatar>
            {event ? (
              <Avatar className={styles.orangeAvatar}>{event.eventId}</Avatar>
            ) : (
              <Skeleton
                className={styles.skeletonAvatar}
                variant="circle"
                width={40}
                height={40}
              />
            )}
          </ListItemAvatar>
        </ListItemAvatar>
        {event ? (
          <ListItemText primary={event.title} secondary={event.description} />
        ) : (
          <ListItemText
            primary={<Skeleton height={6} width="30%" />}
            secondary={
              <>
                <Skeleton height={6} width="80%" />
                <Skeleton height={6} width="40%" />
              </>
            }
          />
        )}

        <ListItemSecondaryAction style={{ display: "flex" }}>
          <div style={{ display: "flex", marginRight: 40, padding: 5 }}>
            {event ? (
              <>
                {" "}
                <Avatar
                  className={
                    props.event.emailTemplate
                      ? styles.orangeAvatar
                      : styles.greyAvatar
                  }
                >
                  <FontAwesomeIcon icon="envelope-square" />
                </Avatar>
                <Avatar
                  className={
                    props.event.smsTemplate
                      ? styles.orangeAvatar
                      : styles.greyAvatar
                  }
                >
                  <FontAwesomeIcon icon="sms" />
                </Avatar>
                <Avatar
                  className={
                    props.event.pushTemplate
                      ? styles.orangeAvatar
                      : styles.greyAvatar
                  }
                >
                  <FontAwesomeIcon icon="mobile-alt" />
                </Avatar>
                <Avatar
                  className={
                    props.event.botTemplate
                      ? styles.orangeAvatar
                      : styles.greyAvatar
                  }
                >
                  <FontAwesomeIcon icon={faRobot} />
                </Avatar>
              </>
            ) : (
              <>
                <Skeleton
                  className={styles.skeletonAvatar}
                  variant="circle"
                  width={40}
                  height={40}
                />{" "}
                <Skeleton
                  className={styles.skeletonAvatar}
                  variant="circle"
                  width={40}
                  height={40}
                />{" "}
                <Skeleton
                  className={styles.skeletonAvatar}
                  variant="circle"
                  width={40}
                  height={40}
                />
                <Skeleton
                  className={styles.skeletonAvatar}
                  variant="circle"
                  width={40}
                  height={40}
                />
              </>
            )}
          </div>
          <IconButton
            onClick={e => props.onDelete(event)}
            edge="end"
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  );
};

const EventList: React.FunctionComponent<EventListProps> = eventListProps => {
  const styles = useStyles();
  const { events, onSelect, isFetching, onDelete } = eventListProps;
  const items = isFetching ? Array.from(new Array(5)) : events;
  return (
    <List className={styles.root}>
      {items.map((event, index) => (
        <EventItem
          key={event ? event.eventId : index}
          event={event}
          onSelect={onSelect}
          onDelete={onDelete}
        />
      ))}
    </List>
  );
};
export default EventList;
