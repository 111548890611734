import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { ApiError } from "../models/apiError";
import { errorsReducer } from "./errorReducer";
import { assemblyReducer, IAssemblyStateBranch } from "../ducks/assembly";
import { authReducer, IAuthStateBranch } from "../ducks/auth";
import { INewsStateBranch, newsReducer } from "../ducks/news";
import createEventServiceReducer, {
  IEventAppReduxState
} from "../eventTemplateApp/reduxx/reducer";
import { ISettingsBranch, settingsReducer } from "../ducks/settings";
import { IPushBranch, pushReducer } from "../ducks/push";

export interface IReduxState {
  router: any;
  error: ApiError;
  assemblyBranch: IAssemblyStateBranch;
  authBranch: IAuthStateBranch;
  newsBranch: INewsStateBranch;
  eventTemplatesBranch: IEventAppReduxState;
  settings: ISettingsBranch;
  pushBranch: IPushBranch;
}

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    assemblyBranch: assemblyReducer,
    error: errorsReducer,
    authBranch: authReducer,
    newsBranch: newsReducer,
    settings: settingsReducer,
    pushBranch: pushReducer,
    eventTemplatesBranch: createEventServiceReducer()
  });
