import { RequestQueryBuilder } from "@nestjsx/crud-request";
import axios from "axios";
import IPagedResponse from "../base/IPagedResponse";
import BaseApiService, { IRequestOptions } from "../base/baseApiService";

export default class CrudService<R> extends BaseApiService {
  constructor(private readonly entityName: string) {
    super();
  }

  public async getMany<R>(
    limit: number,
    page: number,
    options?: IRequestOptions
  ): Promise<IPagedResponse<R>> {
    let queryBuilder = RequestQueryBuilder.create();
    queryBuilder = queryBuilder
      .sortBy({ field: "id", order: "ASC" })
      .setLimit(limit)
      .setPage(page);
    const query = queryBuilder.query();
    const get = axios.get(
      `/api/${this.entityName}?${query}`,
      this.getConfig(options)
    );
    return await this.executeRequest<IPagedResponse<R>>(get);
  }

  public async updateOne<R>(
    id: number | string,
    data: R,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.patch(
        `/api/${this.entityName}/${id}`,
        data,
        this.getConfig(options)
      )
    );
  }

  public async createOne<R>(data: R, options?: IRequestOptions): Promise<R> {
    return await this.executeRequest(
      axios.post(`/api/${this.entityName}`, data, this.getConfig(options))
    );
  }

  public async deleteOne<R>(
    id: number | string,
    options?: IRequestOptions
  ): Promise<R> {
    return await this.executeRequest(
      axios.delete(`/api/${this.entityName}/${id}`, this.getConfig(options))
    );
  }
}
