import React, { useEffect } from "react";
import CrudTableContainer from "../components/crudTable";
import IAssemblyDto from "../../../api/responses/IAssemblyDto";

interface IProps {}

const AssemblyTab = (props: IProps) => {
  const BundleTable = CrudTableContainer<IAssemblyDto>();
  return (
    <BundleTable
      entityName="assembly"
      title="Сборки"
      dense={true}
      columns={[
        { title: "Id", field: "id", editable: "never", hidden: false },
        { title: "buildNumber", field: "buildNumber" },
        { title: "versionName", field: "versionName" },
        { title: "versionCode", field: "versionCode" },
        { title: "branch", field: "branch" },
        { title: "bundleId", field: "bundleId" },
        { title: "size", field: "size" },
        { title: "path", field: "path" },
        { title: "fileName", field: "fileName" }
      ]}
    />
  );
};

export default AssemblyTab;
