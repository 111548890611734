// Store Models
import { LoadingData } from "../models/loadingData";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import appConfig from "../appconf";
import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, delay } from "redux-saga/effects";
import { bindAsyncAction } from "typescript-fsa-redux-saga";
import EventApiService from "../api/eventApiService";
import { EventDto } from "../api/requests/event.dto";

import { Record } from "immutable";
import { ApiError } from "../models/apiError";
import { goBack, push } from "connected-react-router";

export const moduleName = "event";
const prefix = `${appConfig.appName}/${moduleName}`;

export interface EventModel {
  events: LoadingData<EventDto[]>;
  allEvents: LoadingData<EventDto[]>;
  editedEvent: LoadingData<EventDto>;
}

export interface CreateOrUpdateEventPayload {
  event: EventDto;
  isUpdate: boolean;
}

const factory = actionCreatorFactory(prefix);

export const getEvents = factory.async<number, EventDto[], ApiError>(
  "GET_EVENTS_BY_STREAM_ID"
);

export const getAllEvents = factory.async<void, EventDto[], ApiError>(
  "GET_ALL_EVENTS"
);

export const getEventById = factory.async<number, EventDto, ApiError>(
  "GET_EVENT_BY_ID"
);

export const deleteEvent = factory.async<EventDto, boolean, ApiError>(
  "DELETE_EVENT"
);

export const createOrUpdateEvent = factory.async<
  CreateOrUpdateEventPayload,
  EventDto,
  ApiError
>("CREATE_OR_UPDATE_EVENT");

export const createEmpty = Record<EventModel>({
  events: { isFetching: false, data: [], isError: false },
  allEvents: { isFetching: false, data: [], isError: false },
  editedEvent: {
    isFetching: false,
    data: { title: "", description: "", eventId: 0, streamId: 0 },
    isError: false
  }
});

export const eventReducer = reducerWithInitialState(createEmpty())
  // getEventsByStreamId
  .case(getEvents.started, state => state.setIn(["events", "isFetching"], true))
  .case(getEvents.failed, state =>
    state
      .setIn(["events", "isFetching"], false)
      .setIn(["events", "isError"], true)
  )
  .case(getEvents.done, (state, payload) =>
    state
      .setIn(["events", "isFetching"], false)
      .setIn(["events", "data"], payload.result)
  )
  //allevents
  .case(getAllEvents.started, state =>
    state.setIn(["allEvents", "isFetching"], true)
  )
  .case(getAllEvents.failed, state =>
    state
      .setIn(["allEvents", "isFetching"], false)
      .setIn(["allEvents", "isError"], true)
  )
  .case(getAllEvents.done, (state, payload) =>
    state
      .setIn(["allEvents", "isFetching"], false)
      .setIn(["allEvents", "data"], payload.result)
  )
  //deleteEvent
  .case(deleteEvent.started, state =>
    state.setIn(["events", "isFetching"], true)
  )
  .case(deleteEvent.failed, state =>
    state
      .setIn(["events", "isFetching"], false)
      .setIn(["events", "isError"], true)
  )
  .case(deleteEvent.done, (state, payload) =>
    state
      .setIn(["events", "isFetching"], false)
      .updateIn(["events", "data"], list =>
        list.filter((x: any) => x.eventId !== payload.params.eventId)
      )
  )

  // getEventsByStreamId
  .case(getEventById.started, state =>
    state.setIn(["editedEvent", "isFetching"], true)
  )
  .case(getEventById.failed, state =>
    state
      .setIn(["editedEvent", "isFetching"], false)
      .setIn(["editedEvent", "isError"], true)
  )
  .case(getEventById.done, (state, payload) =>
    state
      .setIn(["editedEvent", "isFetching"], false)
      .setIn(["editedEvent", "data"], payload.result)
  )
  //add
  .case(createOrUpdateEvent.started, state =>
    state.setIn(["editedEvent", "isFetching"], true)
  )
  .case(createOrUpdateEvent.failed, state =>
    state.setIn(["editedEvent", "isFetching"], false)
  )
  .case(createOrUpdateEvent.done, (state, payload) =>
    state
      .setIn(["editedEvent", "isFetching"], false)
      .setIn(["editedEvent", "data"], payload.result)
  );

const getEventsByStreamIdWorker = bindAsyncAction(getEvents, {
  skipStartedAction: true
})(function*(streamId: number): SagaIterator {
  const apiService = new EventApiService();
  const response = yield call(
    [apiService, apiService.getEventsByStreamId],
    streamId
  );
  //TODO костыль на добавление небольшой задержки, чтобы показать скелетон
  yield delay(500);
  return response;
});

const getEventByIdWorker = bindAsyncAction(getEventById, {
  skipStartedAction: true
})(function*(eventId: number): SagaIterator {
  const apiService = new EventApiService();
  const response = yield call([apiService, apiService.getEventById], eventId);
  //TODO костыль на добавление небольшой задержки, чтобы показать скелетон
  return response;
});

const getAllEventsWorker = bindAsyncAction(getAllEvents, {
  skipStartedAction: true
})(function*(): SagaIterator {
  const apiService = new EventApiService();
  const response = yield call([apiService, apiService.getAllEvents]);
  return response;
});

const createOrUpdateEventWorker = bindAsyncAction(createOrUpdateEvent, {
  skipStartedAction: true
})(function*(payload: CreateOrUpdateEventPayload): SagaIterator {
  const apiService = new EventApiService();
  let result;
  if (payload.isUpdate) {
    result = yield call([apiService, apiService.updateEvent], payload.event);
  } else {
    result = yield call([apiService, apiService.createEvent], payload.event);
  }
  yield put(goBack());
  return result;
});

const deleteEventWorker = bindAsyncAction(deleteEvent, {
  skipStartedAction: true
})(function*(payload: EventDto): SagaIterator {
  const apiService = new EventApiService();
  const response = yield call([apiService, apiService.deleteEvent], payload);
  return response;
});

export function* saga(): SagaIterator {
  yield takeEvery<Action<number>>(getEvents.started, action =>
    getEventsByStreamIdWorker(action.payload)
  );
  yield takeEvery<Action<number>>(getEventById.started, action =>
    getEventByIdWorker(action.payload)
  );
  yield takeEvery<Action<CreateOrUpdateEventPayload>>(
    createOrUpdateEvent.started,
    action => createOrUpdateEventWorker(action.payload)
  );
  yield takeEvery<Action<EventDto>>(deleteEvent.started, action =>
    deleteEventWorker(action.payload)
  );
  yield takeEvery<Action<void>>(getAllEvents.started, () =>
    getAllEventsWorker()
  );
}
