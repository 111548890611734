import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isAuth } from "../../utils/authUtils";

interface IProps extends RouteComponentProps {}

export const requireAuth = <P extends object>(
  ComposedComponent: React.ComponentType<P>
) => {
  const RequireAuth: React.FC<IProps> = (props: IProps): JSX.Element => {
    const auth: boolean = isAuth();
    useEffect(() => {
      if (!auth) {
        props.history.push("/login");
      }
    });

    return <>{auth ? <ComposedComponent {...(props as P)} /> : null}</>;
  };

  return withRouter(RequireAuth);
};
