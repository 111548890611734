import React, { useEffect, useState } from "react";
import IUserDto from "../../../api/responses/IUserDto";
import SubscriptionBlock from "../../profile/components/subscriptionBlock";
import NotificationService from "../../../api/notificationService";
import IUserSubscriptionDto from "../../../api/responses/IUserSubscriptionDto";
import { ToggleChannelType } from "../../../eventTemplateApp/components/pages/event/components/toggleChannelButtons";
import { call } from "redux-saga/effects";
import ISubscriptionDto from "../../../api/responses/ISubscriptionDto";

interface IProps {
  user: IUserDto;
}

const SubscriptionDetailPanel = (props: IProps) => {
  const [subscriptions, setSubscriptions] = useState<IUserSubscriptionDto[]>(
    []
  );

  const [subscriptionsFetching, setSubscriptionsFetching] = useState<boolean>(
    false
  );

  const changeSubscription = async (
    subscription: IUserSubscriptionDto,
    state: boolean,
    channelType: ToggleChannelType
  ) => {
    const notificationService = new NotificationService();
    try {
      setChangeSubscriptionFetching(true);
      const newOrUpdatedSubscription: ISubscriptionDto = {
        isEmail: subscription.isEmail,
        isPush: subscription.isPush,
        isSms: subscription.isSms,
        streamId: subscription.streamId,
        id: subscription.subscriptionId
      };
      switch (channelType) {
        case ToggleChannelType.email:
          newOrUpdatedSubscription.isEmail = state;
          break;

        case ToggleChannelType.push:
          newOrUpdatedSubscription.isPush = state;
          break;

        case ToggleChannelType.sms:
          newOrUpdatedSubscription.isSms = state;
          break;
      }

      if (subscription.subscriptionId) {
        //существующая подписка
        const result = await notificationService.updateSubscription(
          props.user.id,
          newOrUpdatedSubscription
        );
      } else {
        const result = await notificationService.createSubscription(
          props.user.id,
          newOrUpdatedSubscription
        );
        setChangeSubscriptionFetching(false);
      }
      fetchSubscriptions();
    } catch (e) {
      setChangeSubscriptionFetching(false);
    }
  };

  const [changeSubscriptionFetching, setChangeSubscriptionFetching] = useState<
    boolean
  >(false);

  const fetchSubscriptions = async () => {
    const notificationService = new NotificationService();
    try {
      setSubscriptionsFetching(true);
      const result = await notificationService.getSubscriptions(props.user.id);
      setSubscriptions(result);
      setSubscriptionsFetching(false);
    } catch (e) {
      setSubscriptionsFetching(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <SubscriptionBlock
        subscriptions={subscriptions}
        subscriptionsFetching={subscriptionsFetching}
        changeSubscriptionFetching={false}
        subscriptionChange={changeSubscription}
      />
    </div>
  );
};

export default SubscriptionDetailPanel;
