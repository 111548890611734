import * as guid from "uuid";

import * as firebase from "firebase";

import {
  ISettingsBranch,
  setGuid,
  setVersion,
  setPushToken
} from "../ducks/settings";
import appConfig from "../appConfig";
import { logout } from "../ducks/auth";
import { setPushData } from "../ducks/push";

export default class WebPushManager {
  private _firebaseConfig: any;
  private _settings: ISettingsBranch;
  private _dispatch: any;

  constructor(appSettings: ISettingsBranch, dispatch: any) {
    this._settings = appSettings;
    this._dispatch = dispatch;
    this._firebaseConfig = {
      apiKey: "AIzaSyDbXqVe0UDEfyxcP59PIlBIfuSwb_rrZgM",
      authDomain: "assembly-storage.firebaseapp.com",
      databaseURL: "https://assembly-storage.firebaseio.com",
      projectId: "assembly-storage",
      storageBucket: "assembly-storage.appspot.com",
      messagingSenderId: "139462089398",
      appId: "1:139462089398:web:8ab236b2fc241e315a6417"
    };
  }

  public checkSettingsAndInitPush = () => {
    if (!this._settings.guid) {
      this._dispatch(setGuid(guid.v4()));
    }

    if (!this._settings.version) {
      // мажорное обновление. Сбрасываем авторизацию
      this._dispatch(logout());
      this._dispatch(setVersion(appConfig.version));
    }

    // migrate if needed

    if (this._settings.version === "X.X.X" && appConfig.version === "X.X.Y") {
      // мажорное обновление. Сбрасываем авторизацию
      this._dispatch(logout());
      this._dispatch(setVersion(appConfig.version));
    }

    this.initializePush();
  };

  private initializePush = () => {
    try {
      firebase.initializeApp(this._firebaseConfig);
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          console.log("Have Permission");
          return messaging.getToken();
        })
        .then(token => {
          if (token) {
            this._dispatch(setPushToken(token));
          }
          console.log("FCM Token:", token);
        })
        .catch(error => {
          if (error.code === "messaging/permission-blocked") {
            console.log("Please Unblock Notification Request");
          } else {
            console.log("Error Occurred", error);
          }
        });
      messaging.onMessage(payload => {
        if (payload.notification) {
          this._dispatch(
            setPushData({
              title: payload.notification.title,
              body: payload.notification.body,
              clickAction: payload.notification.click_action,
              icon: payload.notification.icon
            })
          );
        }

        console.log("Message received. ", payload);
        // ...
      });
    } catch (e) {
      console.log(e);
    }
  };
}
