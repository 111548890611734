import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import createAppReducer, { IReduxState } from "./reducer";

import rootSaga from "./saga";
import eventTemplatesSaga from "../eventTemplateApp/reduxx/saga";
import WebPushManager from "../push/webPushManager";
import { PermanentlySavedData } from "../models/permanentlySavedData";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    console.log(error.message);
  }
});
export let configuredStore: Store<IReduxState>;
const appReducer = createAppReducer(history);
const composedEnhancers = composeWithDevTools(
  applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
);

configuredStore = createStore(appReducer, composedEnhancers);
configuredStore.subscribe(() => {
  const currentState = configuredStore.getState();
  let saved: PermanentlySavedData;
  const settings = currentState.settings;
  saved = {
    appSettings: settings
  };
  localStorage.setItem("userState", JSON.stringify(saved));
});

// запуск саг
sagaMiddleware.run(rootSaga);
sagaMiddleware.run(eventTemplatesSaga);

const pushManager = new WebPushManager(
  configuredStore.getState().settings,
  configuredStore.dispatch
);
pushManager.checkSettingsAndInitPush();
