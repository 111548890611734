import React, { useEffect } from "react";
import ProgressIndicator from "../../../components/progressIndicator";
import { createStyles, Divider, Theme, Typography } from "@material-ui/core";
import ToggleChannelButton, {
  ToggleChannelType
} from "../../../eventTemplateApp/components/pages/event/components/toggleChannelButtons";
import IUserSubscriptionDto from "../../../api/responses/IUserSubscriptionDto";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textBlock_body: {
      marginBottom: 10
    },

    subscription: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
      justifyContent: "space-between"
    },
    subscription_title: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    subscription_channels: {
      display: "flex",
      width: 160,
      justifyContent: "space-between"
    },
    subscription_divider: {
      marginBottom: 10
    }
  })
);

interface IProps {
  changeSubscriptionFetching: boolean;
  subscriptionsFetching: boolean;
  subscriptions: IUserSubscriptionDto[];
  subscriptionChange: (
    subscription: IUserSubscriptionDto,
    state: boolean,
    channelType: ToggleChannelType
  ) => void;
}

const SubscriptionBlock = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      {props.changeSubscriptionFetching || props.subscriptionsFetching ? (
        <ProgressIndicator />
      ) : (
        props.subscriptions.map((subscription, index) => (
          <div key={index}>
            <div className={classes.subscription}>
              <div className={classes.subscription_title}>
                <Typography variant="h5">{subscription.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {subscription.description}
                </Typography>
              </div>

              <div className={classes.subscription_channels}>
                <ToggleChannelButton
                  channelType={ToggleChannelType.email}
                  template={subscription.isEmail}
                  onChange={(selected: boolean) =>
                    props.subscriptionChange(
                      subscription,
                      selected,
                      ToggleChannelType.email
                    )
                  }
                />
                <ToggleChannelButton
                  channelType={ToggleChannelType.push}
                  template={subscription.isPush}
                  onChange={(selected: boolean) =>
                    props.subscriptionChange(
                      subscription,
                      selected,
                      ToggleChannelType.push
                    )
                  }
                />
                <ToggleChannelButton
                  channelType={ToggleChannelType.sms}
                  template={subscription.isSms}
                  onChange={(selected: boolean) =>
                    props.subscriptionChange(
                      subscription,
                      selected,
                      ToggleChannelType.sms
                    )
                  }
                />
              </div>
            </div>
            {index !== props.subscriptions.length - 1 && (
              <Divider className={classes.subscription_divider} />
            )}
          </div>
        ))
      )}
    </>
  );
};

export default SubscriptionBlock;
