import * as React from "react";
import { connect } from "react-redux";
import { IReduxState } from "../../reduxx/reducer";
import { PushData } from "../../ducks/push";
import NotificationSnack from "../notificationSnack";
import { useEffect } from "react";
import { useState } from "react";

interface IStatedProps {
  pushData: PushData;
}

interface IProps extends IStatedProps {
  children?: React.ReactNode;
}

const PushProvider = (props: IProps) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (props.pushData.body) {
      setOpen(true);
    }
  }, [props.pushData]);
  return (
    <>
      <NotificationSnack
        text={props.pushData.body}
        title={props.pushData.title}
        clickAction={props.pushData.clickAction}
        icon={props.pushData.icon}
        isOpen={open}
      />
      {props.children}
    </>
  );
};

const mapStateToProps = (state: IReduxState): IStatedProps => ({
  pushData: state.pushBranch.push
});

export default connect<IStatedProps, void, {}, IReduxState>(mapStateToProps)(
  PushProvider
);
