import React, { useEffect } from "react";
import {
  createStyles,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { IReduxState } from "../../reduxx/reducer";
import {
  getAssemblies,
  getBundles,
  getBundlesByProject,
  getProjects,
  IAssembliesFilter,
  IAssembliesFilterChange,
  IGetAssemblies,
  setFilter
} from "../../ducks/assembly";
import IAssemblyDto from "../../api/responses/IAssemblyDto";
import { Platform } from "../../models/platform";
import { makeStyles } from "@material-ui/core/styles";
import IProjectDto from "../../api/responses/IProjectDto";
import IBundleDto from "../../api/responses/IBundleDto";
import MaterialTable, { MTableToolbar } from "material-table";
import { RouteComponentProps, withRouter } from "react-router";
import ConvertersUtils from "../../utils/convertersUtils";
import convertersUtils from "../../utils/convertersUtils";
import NewsView from "./components/newsView";
import PanelWithHeader from "./components/panelWithHeader";

interface IStatedProps {
  assembliesFilter: IAssembliesFilter;
  assemblies: IAssemblyDto[];
  projects: IProjectDto[];
  bundles: IBundleDto[];
  assembliesFetching: boolean;
}

interface IDispatchedProps {
  getAssemblies: (param: IGetAssemblies) => void;
  getProjects: () => void;
  getBundles: () => void;
  setFilter: (changedFilter: IAssembliesFilterChange) => void;
}

interface IProps extends IStatedProps, IDispatchedProps, RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    tableBlock: {}
  })
);

const MainPage = (props: IProps) => {
  const classes = useStyles();

  const handlePickerChange = (name: keyof IAssembliesFilter) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    switch (name) {
      case "selectedBundleId":
        props.setFilter({
          oldFilter: props.assembliesFilter,
          newFilter: {
            ...props.assembliesFilter,
            selectedBundleId: event.target.value as string
          }
        });

        break;

      case "selectedProjectId":
        props.setFilter({
          oldFilter: props.assembliesFilter,
          newFilter: {
            ...props.assembliesFilter,
            selectedProjectId: event.target.value as string,
            selectedBundleId: undefined
          }
        });

        break;

      case "selectedPlatform":
        props.setFilter({
          oldFilter: props.assembliesFilter,
          newFilter: {
            ...props.assembliesFilter,
            selectedPlatform: event.target.value as Platform,
            selectedBundleId: undefined
          }
        });

        break;
    }
  };

  useEffect(() => {
    props.getBundles();
    props.getProjects();
    props.getAssemblies({
      platform: props.assembliesFilter.selectedPlatform,
      projectId: props.assembliesFilter.selectedProjectId,
      bundleId: props.assembliesFilter.selectedBundleId
    });
  }, []);

  const onItemDownloadClick = (
    event: any,
    data: IAssemblyDto | IAssemblyDto[]
  ) => {
    if (data instanceof Array) {
    } else {
      const item = data as IAssemblyDto;
      const link = item.links[0];
      if (link) {
        props.history.push(`/download/${link.id}`);
      }
    }
  };

  const onItemInfoClick = (event: any, data: IAssemblyDto | IAssemblyDto[]) => {
    if (data instanceof Array) {
    } else {
      const item = data as IAssemblyDto;
      props.history.push(`/assembly/${item.id}`);
    }
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={2}>
        <PanelWithHeader title="Фильтры">
          <FormControl className={classes.formControl}>
            <InputLabel id="platform" htmlFor="platform-native">
              Платформа
            </InputLabel>
            <Select
              id="platform"
              value={props.assembliesFilter.selectedPlatform}
              onChange={handlePickerChange("selectedPlatform")}
              inputProps={{
                name: "platform",
                id: "platform"
              }}
            >
              <MenuItem value={Platform.ANDROID}>Android</MenuItem>

              <MenuItem value={Platform.IOS}>IOS</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="project" htmlFor="project-native">
              Проект
            </InputLabel>
            <Select
              id="project"
              value={props.assembliesFilter.selectedProjectId}
              onChange={handlePickerChange("selectedProjectId")}
              inputProps={{
                name: "project",
                id: "project"
              }}
            >
              <MenuItem value={undefined}>Сбросить</MenuItem>
              {props.projects.map((project, index) => (
                <MenuItem key={index} value={project.id}>
                  {project.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="bundle" htmlFor="bundle-native">
              Bundle
            </InputLabel>
            <Select
              id="bundle"
              value={props.assembliesFilter.selectedBundleId}
              onChange={handlePickerChange("selectedBundleId")}
              inputProps={{
                name: "bundle",
                id: "bundle"
              }}
            >
              <MenuItem value={undefined}>Сбросить</MenuItem>
              {props.bundles.map((bundle, index) => (
                <MenuItem key={index} value={bundle.id}>
                  {bundle.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </PanelWithHeader>
        <PanelWithHeader title="Последние поступления">
          <NewsView />
        </PanelWithHeader>
      </Grid>
      <Grid item={true} xs={10}>
        <div className={classes.tableBlock}>
          <MaterialTable
            title="Сборки"
            onRowClick={(event, rowData, togglePanel) =>
              onItemDownloadClick(event, rowData as IAssemblyDto)
            }
            components={{
              Toolbar: bodyProps => (
                <>
                  <MTableToolbar {...bodyProps} />
                  {props.assembliesFetching && <LinearProgress />}
                </>
              )
            }}
            columns={[
              {
                title: "Проект",
                render: rowData => (
                  <>
                    <Typography variant="body1">
                      {rowData.bundle.project.title}
                    </Typography>
                    <Typography variant="caption">
                      {rowData.bundle.id}
                    </Typography>
                  </>
                )
              },
              { title: "Название файла", field: "fileName" },
              { title: "Ветка", field: "branch" },
              {
                title: "Время поступления",
                field: "createdAt",
                render: rowData =>
                  convertersUtils.convertDateTimeToFormat(
                    rowData.createdAt,
                    "DD MMM YYYY HH:mm"
                  )
              },
              { title: "Версия", field: "versionName" },
              { title: "Код версии", field: "versionCode", type: "numeric" },
              {
                title: "Размер",
                field: "size",
                type: "numeric",
                render: rowData => `${ConvertersUtils.formatSize(rowData.size)}`
              }
            ]}
            localization={{
              grouping: {
                placeholder:
                  "Для группировки - перетащите названия колонки в эту область"
              },
              header: {
                actions: "Информация"
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} из {count}",
                labelRowsSelect: "строк"
              },
              toolbar: { searchTooltip: "Поиск", searchPlaceholder: "Поиск" },
              body: {
                emptyDataSourceMessage: "Нет подходящих для условий сборок",
                filterRow: {
                  filterTooltip: "Фильтр"
                }
              }
            }}
            data={props.assemblies}
            actions={[
              {
                icon: () => <InfoOutlinedIcon />,
                tooltip: "Информация по сборке",
                onClick: onItemInfoClick
              }
            ]}
            options={{
              grouping: true,
              sorting: true,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: true,
              pageSize: 10
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IReduxState): IStatedProps => ({
  assembliesFilter: state.assemblyBranch.assembliesFilter,
  assemblies: state.assemblyBranch.assembliesData.data,
  assembliesFetching: state.assemblyBranch.assembliesData.isFetching,
  projects: state.assemblyBranch.projectsData.data,
  bundles: getBundlesByProject(state)
});

export default connect<IStatedProps, IDispatchedProps, {}, IReduxState>(
  mapStateToProps,
  {
    getAssemblies: getAssemblies.started,
    getProjects: () => getProjects.started(),
    getBundles: () => getBundles.started(),
    setFilter: setFilter
  }
)(withRouter(MainPage));
