import * as React from "react";
import {
  AppBar,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Toolbar,
  Typography
} from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import LinearProgress from "@material-ui/core/LinearProgress";

interface Props {
  children?: React.ReactNode;
  title: string;
  isError: boolean;
  isBlockingLoader?: boolean;
  isLinearLoader?: boolean;
  additionalButtons?: React.ReactNode;
}

export default class TitlePageWrapper extends React.Component<Props> {
  public render() {
    const isError = this.props.isError;
    let content;
    if (isError) {
      content = (
        <div style={{ display: "flex" }}>
          <ErrorOutline />
          <Typography style={{ marginLeft: 10 }} variant="body1">
            Контент не может быть отображён из-за ошибки
          </Typography>
        </div>
      );
    } else if (this.props.isBlockingLoader) {
      content = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CircularProgress />
          <Typography style={{ marginLeft: 5 }} variant="body1">
            Подождите ...
          </Typography>
        </div>
      );
    } else {
      content = this.props.children;
    }

    return (
      <Paper style={{ height: "100%", overflow: "auto" }}>
        <AppBar
          style={{ boxShadow: "none" }}
          position="static"
          component="div"
          color="transparent"
        >
          <Toolbar>
            <Typography style={{ flexGrow: 1 }} variant="h2" color="inherit">
              {this.props.title}
            </Typography>

            {this.props.additionalButtons}
          </Toolbar>

          {this.props.isLinearLoader && <LinearProgress color="primary" />}
        </AppBar>
        <div style={{ height: "100%", padding: 10 }}>{content}</div>
      </Paper>
    );
  }
}
