import { deleteEvent, getEvents } from "../../../ducks/event";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { EventDto } from "../../../api/requests/event.dto";
import { Fab, WithStyles, withStyles } from "@material-ui/core";
import fabButtonStyles, { FabButtonStylesKey } from "../../../variables/styles";
import AddIcon from "@material-ui/icons/Add";
import EventList from "./components/eventList";
import TitlePageWrapper from "../../titlePageWrapper";
import { IReduxState } from "../../../../reduxx/reducer";

export interface State {
  streamId: string;
}

interface DispatchedProps {
  getEvents: (streamId: number) => void;
  deleteEvent: (event: EventDto) => void;
}

interface StatedProps {
  events: EventDto[];
  isFetching: boolean;
  isError: boolean;
}

interface Props extends DispatchedProps, StatedProps, RouteComponentProps {}

class EventListPage extends React.Component<
  Props & WithStyles<FabButtonStylesKey>,
  State
> {
  constructor(props: Props & WithStyles<FabButtonStylesKey>) {
    super(props);
    const params = this.props.match.params;
    const streamId = (params as any).streamId;
    this.state = { streamId };
  }
  public componentDidMount(): void {
    if (this.state.streamId) {
      this.props.getEvents(parseInt(this.state.streamId));
    }
  }

  public render() {
    const { classes } = this.props;
    return (
      <>
        <TitlePageWrapper
          isLinearLoader={this.props.isFetching}
          title="События"
          isError={this.props.isError}
        >
          <EventList
            isFetching={this.props.isFetching}
            onDelete={event => this.props.deleteEvent(event)}
            onSelect={this.onItemSelect}
            events={this.props.events}
          />

          <div style={{ position: "fixed" }}>
            <Fab
              variant="extended"
              onClick={this.createNewEvent}
              className={classes.fab}
            >
              <AddIcon />
              Добавить
            </Fab>
          </div>
        </TitlePageWrapper>
      </>
    );
  }

  private onItemSelect = (eventId?: number) => {
    this.props.history.push(
      `/admin/templates/${this.state.streamId}/event/edit/${eventId}`
    );
  };

  private createNewEvent = () => {
    this.props.history.push(
      `/admin/templates/${this.state.streamId}/event/add`
    );
  };
}

const mapStateToProps = (state: IReduxState, ownProps: any): StatedProps => ({
  events: state.eventTemplatesBranch.event.getIn([
    "events",
    "data"
  ]) as EventDto[],
  isFetching: state.eventTemplatesBranch.event.getIn([
    "events",
    "isFetching"
  ]) as boolean,
  isError: state.eventTemplatesBranch.event.getIn([
    "events",
    "isError"
  ]) as boolean
});

export default connect(mapStateToProps, {
  getEvents: getEvents.started,
  deleteEvent: deleteEvent.started
})(withStyles(fabButtonStyles)(EventListPage));
