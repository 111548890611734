import React, { useEffect } from "react";
import IProjectDto from "../../../api/responses/IProjectDto";
import CrudTableContainer from "../components/crudTable";
import { EventDto } from "../../../eventTemplateApp/api/requests/event.dto";

interface IProps {
  events: EventDto[];
}

const ProjectTab = (props: IProps) => {
  const ProjectTable = CrudTableContainer<IProjectDto>();
  return (
    <ProjectTable
      entityName="project"
      title="Проекты"
      columns={[
        {
          title: "Иконка",
          field: "iconUrl",
          render: rowData => (
            <img
              src={rowData.iconUrl}
              style={{ width: 40, borderRadius: "50%" }}
            />
          )
        },
        { title: "Id", field: "id", editable: "onAdd", hidden: false },
        { title: "Название", field: "title" },
        {
          title: "Идентификатор события получения новой сборки",
          field: "eventUploadId",
          lookup: props.events.reduce(
            (acc, event) => ({
              ...acc,
              [`${event.streamId}:${event.eventId}`]: `${event.title} (№${event.eventId})`
            }),
            {}
          )
        }
      ]}
    />
  );
};

export default ProjectTab;
