import React from "react";
import Helmet from "react-helmet";

interface IProps {
  title: string;
  imageUrl: string;
  url: string;
  description: string;
  date: string;
}

function ShareProperties(props: IProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content="Assembly Storage" />
      <meta property="og:description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={props.imageUrl} />
      <meta property="og:image:secure_url" content={props.imageUrl} />
      <meta name="twitter:image" content={props.imageUrl} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="500" />
      <meta property="og:url" content={props.url} />
      <meta property="assembly:published_time" content={props.date} />
    </Helmet>
  );
}

export default ShareProperties;
