import { ILoadingData } from "../models/loadingData";
import NewsItem from "../api/responses/INewsItemDto";
import actionCreatorFactory, { Action } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiError } from "../models/apiError";
import { bindAsyncAction } from "../utils/bindAsyncAction";
import { SagaIterator } from "redux-saga";
import ApiService from "../api/apiService";
import { call, takeEvery } from "redux-saga/effects";
import appConfig from "../appConfig";

export const moduleName = "news";
const prefix = `${appConfig.appName}/${moduleName}`;
const factory = actionCreatorFactory(prefix);

export interface INewsStateBranch {
  lastNews: ILoadingData<NewsItem[]>;
}

export const createEmpty = (): INewsStateBranch => {
  return {
    lastNews: { isFetching: false, isError: false, data: [] }
  };
};

export const getTopNews = factory.async<void, NewsItem[], ApiError>(
  "GET_TOP_NEWS"
);

export const newsReducer = reducerWithInitialState(createEmpty())
  .case(getTopNews.started, state => ({
    ...state,
    lastNews: { ...state.lastNews, isFetching: true }
  }))
  .case(getTopNews.failed, state => ({
    ...state,
    lastNews: { ...state.lastNews, isFetching: false }
  }))
  .case(getTopNews.done, (state, payload) => ({
    ...state,
    lastNews: {
      isFetching: false,
      data: payload.result,
      isError: false
    }
  }));

const getTopNewsWorker = bindAsyncAction(getTopNews)(function*(): SagaIterator {
  const apiService = new ApiService();
  const response = yield call([apiService, apiService.getTopNews], 5);
  return response;
});

export function* saga(): SagaIterator {
  yield takeEvery<Action<void>>(getTopNews.started, getTopNewsWorker);
}
