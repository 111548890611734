import React from "react";
import {
  createStyles,
  Divider,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pickerBlock: {
      display: "flex",
      flexDirection: "column",
      marginRight: 10,
      padding: 5,
      marginBottom: 20
    },
    title: {
      padding: 10,
      width: 500
    }
  })
);

interface IProps {
  children: React.ReactNode;
  title: string;
}
const PanelWithHeader = (props: IProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.pickerBlock}>
      <Typography className={classes.title} variant="h6">
        {props.title}
      </Typography>
      <Divider />
      {props.children}
    </Paper>
  );
};

export default PanelWithHeader;
