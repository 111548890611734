import { colors } from "@material-ui/core";

import palette from "../palette";

export default {
  root: {
    color: palette.icon,

    "&:hover": {
      backgroundColor: colors.grey[100],
      color: palette.icon
    },
    "&$selected": {
      backgroundColor: palette.secondary.main,
      color: "white",
      "&:hover": {
        backgroundColor: colors.grey[100],
        color: palette.icon
      }
    },
    "&:first-child": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    "&:last-child": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    }
  }
};
