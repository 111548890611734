import React, { useEffect } from "react";
import { IReduxState } from "../../../reduxx/reducer";
import { connect } from "react-redux";
import { getTopNews } from "../../../ducks/news";
import INewsItemDto from "../../../api/responses/INewsItemDto";
import {
  Avatar,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import convertersUtils from "../../../utils/convertersUtils";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    inline: {
      display: "inline"
    },
    newsDescription: {
      display: "flex",
      flexDirection: "column"
    },
    title: {
      margin: theme.spacing(4, 0, 2)
    }
  })
);

interface IStatedProps {
  topNews: INewsItemDto[];
  isFetching: boolean;
}

interface IDispatchedProps {
  getTopNews: () => void;
}

interface IProps extends IStatedProps, IDispatchedProps, RouteComponentProps {}

const NewsView = (props: IProps) => {
  const classes = useStyles();
  const goToAssemblyPage = (assemblyId: string) => {
    props.history.push(`/assembly/${assemblyId}`);
  };
  useEffect(() => {
    props.getTopNews();
  }, []);
  return (
    <>
      <List className={classes.root}>
        {props.topNews.map(newsItem => (
          <React.Fragment key={newsItem.assemblyId}>
            <ListItem
              onClick={e => goToAssemblyPage(newsItem.assemblyId)}
              key={newsItem.assemblyId}
              button
            >
              <ListItemAvatar>
                <Avatar alt={newsItem.projectName} src={newsItem.projectIcon} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {newsItem.version}
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                    >
                      {" (" + newsItem.bundleId + ")"}
                    </Typography>
                  </>
                }
                secondary={convertersUtils.convertDateTimeToFormat(
                  newsItem.createdAt,
                  "DD MMMM YYYY HH:mm"
                )}
              />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

const mapStateToProps = (state: IReduxState): IStatedProps => ({
  isFetching: state.newsBranch.lastNews.isFetching,
  topNews: state.newsBranch.lastNews.data
});

export default connect<IStatedProps, IDispatchedProps, {}, IReduxState>(
  mapStateToProps,
  {
    getTopNews: () => getTopNews.started()
  }
)(withRouter(NewsView));
