import { combineReducers } from "redux";
import { streamReducer, StreamModel } from "../ducks/stream";
import { Record } from "immutable";
import { EventModel, eventReducer } from "../ducks/event";
import { ImageModel, imageReducer } from "../ducks/image";

export interface IEventAppReduxState {
  stream: Record<StreamModel>;
  event: Record<EventModel>;
  image: Record<ImageModel>;
}

export default () =>
  combineReducers({
    stream: streamReducer,
    image: imageReducer,
    event: eventReducer
  });
