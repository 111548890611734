import BaseApiService from "./base/baseApiService";
import ISubscriptionDto from "./responses/ISubscriptionDto";
import IUserSubscriptionDto from "./responses/IUserSubscriptionDto";

const apiUrls = {
  subscription: "/notifications/subscription"
};

export default class NotificationService extends BaseApiService {
  constructor() {
    super();
  }

  public async getSubscriptions(userId: string) {
    const url = `${apiUrls.subscription}`;
    return await this.getRequest<IUserSubscriptionDto[]>(`${url}/${userId}`);
  }

  public async createSubscription(
    userId: string,
    subscription: ISubscriptionDto
  ) {
    const url = `${apiUrls.subscription}`;
    return await this.postRequest<IUserSubscriptionDto>(
      `${url}/${userId}`,
      subscription
    );
  }
  public async updateSubscription(
    userId: string,
    subscription: ISubscriptionDto
  ) {
    const url = `${apiUrls.subscription}`;
    return await this.putRequest<IUserSubscriptionDto>(
      `${url}/${userId}/${subscription.id}`,
      subscription
    );
  }
}
