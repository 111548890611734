import { createStyles, Paper, Theme } from "@material-ui/core";
import {
  EmailIcon,
  EmailShareButton,
  MailruShareButton,
  TelegramIcon,
  TelegramShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      padding: 10,
      display: "flex",
      width: 230,
      justifyContent: "space-between"
    }
  })
);

export interface IProps {
  title: string;
}

const SharePanel = (props: IProps) => {
  const classes = useStyles();
  const iconSize = 52;
  const url = window.location.href;
  const telegramUrl = `https://t.me/iv?url=${encodeURIComponent(
    url
  )}&rhash=d6e46311f200cb`;

  return (
    <Paper className={classes.panel}>
      <TelegramShareButton url={telegramUrl}>
        <TelegramIcon size={iconSize} round />
      </TelegramShareButton>

      <VKShareButton url={url} title={props.title}>
        <VKIcon size={iconSize} round />
      </VKShareButton>

      <WhatsappShareButton url={url} title={props.title}>
        <WhatsappIcon size={iconSize} round />
      </WhatsappShareButton>

      <EmailShareButton url={url} subject={props.title}>
        <EmailIcon size={iconSize} round />
      </EmailShareButton>
    </Paper>
  );
};

export default SharePanel;
